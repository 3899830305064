import React from 'react';
import './index.css';
import { AiOutlineDashboard, AiOutlineSetting } from 'react-icons/ai';
import { MdDevicesOther } from 'react-icons/md';
import { Router, useLocation, useNavigate } from 'react-router-dom';
import DiagnosticSvg from '../../images/svgs/DiagnosticSvg';
import DashboardSvg from '../../images/svgs/DashboardSvg';
import AssetsListSvg from '../../images/svgs/AssetsListSvg';
import SettingsSvg from '../../images/svgs/SettingsSvg';
import ProductSvg from '../../images/svgs/ProductSvg';
import { isArray } from 'lodash';
// import { IoSettingsOutline } from 'react-icons/io';

const routes = [
	{
		name: 'dashboard',
		image: DashboardSvg,
		active: false,
		route: '/dashboard',
	},
	{
		name: 'assets',
		image: AssetsListSvg,
		active: false,
		route: ['/assets', '/incidents'],
	},
	{ name: 'settings', image: SettingsSvg, active: false, route: '/account-settings' },
	// {
	// 	name: 'diagnostics',
	// 	image: DiagnosticSvg,
	// 	active: false,
	// 	route: '/diagnostics',
	// },
	// { name: 'products', image: ProductSvg, active: false, route: '/products' },
];

const FooterDashboard = function () {
	const location = useLocation();
	const navigate = useNavigate();

	const goToPath = function (toPath) {
		if (toPath === '/settings') {
			return;
		}

		if (isArray(toPath)) {
			for (let x = 0; x < toPath.length; x++) {
				if (!location.pathname.includes(toPath[x])) {
					navigate(toPath[x]);
					break;
				}
			}
		} else if (!location.pathname.includes(toPath)) {
			navigate(toPath);
		}
	};

	return (
		<div className="dashboard-footer-container">
			{routes.map((el, index) => {
				const Icon = el.image;

				let isActive = false;
				if (isArray(el.route)) {
					for (let x = 0; x < el.route.length; x++) {
						isActive = location.pathname.includes(el.route[x])
							? 'each-footer-icon active'
							: 'each-footer-icon';
						if (isActive.includes('active')) {
							break;
						}
					}
				} else {
					isActive = location.pathname.includes(el.route) ? 'each-footer-icon active' : 'each-footer-icon';
				}

				return (
					<div key={index} onClick={() => goToPath(el.route)} className={isActive}>
						<Icon name={el.name} fill={isActive.includes('active') ? '#333' : '#fff'} />
					</div>
				);
			})}
		</div>
	);
};

export default FooterDashboard;
