import React, { useEffect, useState, useMemo, useRef } from 'react';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useSelector } from 'react-redux';
import _, { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { deleteApi, download, getApi, postApi, uploadToS3 } from '../../services/api';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { AiFillMail, AiOutlineEye, AiOutlineLink } from 'react-icons/ai';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
// import Select from 'react-select';
import countryList from 'react-select-country-list';
// import Modal from 'react-modal';
import currencyList from '../../currencies.js';
import { BiPencil } from 'react-icons/bi';
import { add, format, parseISO } from 'date-fns';
import { useCallback } from 'react';
import { SET_COMPANY_LOGO } from '../../redux/actions';
import { FiEdit2 } from 'react-icons/fi';
import { MdModeEditOutline } from 'react-icons/md';
import { IoMdClose, IoMdContact } from 'react-icons/io';
import { MdOutlineDeleteOutline, MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { BsBoxArrowInLeft, BsBoxArrowInRight, BsBoxArrowUpRight, BsFillTelephoneFill } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { v4 as uuidv4 } from 'uuid';
import { MdModeEdit } from 'react-icons/md';
// import TimePicker from 'react-dropdown-timepicker';
// import DatePicker from 'react-date-picker';
import { KeyboardDatePicker, KeyboardTimePicker, TimePickerView } from '@material-ui/pickers';
import { AssetTypeFromBackend } from '../AssetsManagment/NewAssets';
import {
	Box,
	// Button,
	createMuiTheme,
	Divider,
	Grid,
	Stack,
	TextareaAutosize,
	TextField,
	ThemeProvider,
	Typography,
	Link as ButtonLink,
	FormControl,
	InputLabel,
	MenuItem,
	Select as MUISelect,
	Dialog,
	DialogTitle,
	DialogContent,
} from '@mui/material';
import { teal } from '@mui/material/colors';
import AssetsAllocated, { UnassignedDevices } from './AssestsAllocated';
// import { Container } from 'vertical-timeline-component-react';
import uploadButton from '../../images/Upload_employees.svg';
import { useDropzone } from 'react-dropzone';
import { AiOutlineDelete } from 'react-icons/ai';
import Tooltip from '@mui/material/Tooltip';
import {
	Table,
	Tag,
	Avatar,
	Button,
	Input,
	Col,
	Upload,
	Image,
	DatePicker,
	Drawer,
	Form,
	Row,
	Select,
	Space,
	Tabs,
	Modal,
	Checkbox,
	Steps,
} from 'antd';
import { PlusOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';

import { CreatePO } from './CreatePO.js';
import CompanyBranch from '../CompanyBranch/index.js';
import { MaserAssetTagSetup } from './MaserAssetTagSetup.js';
import { MdDeleteOutline } from 'react-icons/md';
import { render } from '@testing-library/react';
import AgentContent from './AgentContent.js';
import EmployeeModal from './EmployeeModal.js';
import {
	ADMIN_SETUP_CREATE,
	ADMIN_SETUP_VIEW,
	AGENT_COMPUTERS_VIEW,
	ASSET_TAG_SETUP_VIEW,
	ASSET_TYPES_CREATE,
	ASSET_TYPES_DELETE,
	ASSET_TYPES_VIEW,
	COMPANY_BRANCHES_VIEW,
	COMPANY_INFO_EDIT,
	COMPANY_INFO_VIEW,
	CheckPermission,
	CheckPermissionCondition,
	EMPLOYEE_SETUP_DELETE,
	EMPLOYEE_SETUP_EDIT,
	EMPLOYEE_SETUP_VIEW,
	PURCHASE_ORDER_VIEW,
} from '../../utils/Permissions.js';
import { MdOutlineModeEdit } from 'react-icons/md';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { escapeRegExp } from '../../utils/sanitizeFileName.js';
import Search from 'antd/es/input/Search.js';
import Roles from './Roles.js';
import { getLabel } from './getLabel.js';
const { Dragger } = Upload;
const { Search: AntdSearch } = Input;

const AccountSettings = function () {
	const [searchParams] = useSearchParams();
	const [key, setKey] = useState(null);
	const navigate = useNavigate();
	let view = searchParams.get('view');
	console.log(view);
	// const user = useSelector((state) => state.appreducer.user);
	// const [underlineButton, setUnderlineButton] = useState('');
	// const [view, setView] = useState('personal-info');

	// const navigate = useNavigate();

	// const { register, handleSubmit, reset } = useForm();
	// const dispatch = useDispatch();
	// const [showPasswordOld, setshowPasswordOld] = useState(false);
	// const [showPasswordNew, setshowPasswordNew] = useState(false);

	// const onSubmit = async (data) => {
	// 	console.log(data);
	// 	const resp = await postApi({ ...data, email: user.email }, '/users/set-password');
	// 	console.log(resp, 'resp');
	// 	if (resp.type === 'error') {
	// 		dispatch(
	// 			showSnack('PASSWORD_SET_ERROR', {
	// 				label: resp.error,
	// 				timeout: 7000,
	// 			})
	// 		);
	// 	} else {
	// 		reset({ currentPassword: '', newPassword: '' });
	// 		dispatch(
	// 			showSnack('PASSWORD_SET_SUCCESS', {
	// 				label: 'Successfully changed your password.',
	// 				timeout: 7000,
	// 			})
	// 		);
	// 	}
	// };
	// let firstLetter = 'A';

	// if (user.firstName && user.firstName.length > 0) {
	// 	firstLetter = user.firstName[0].toUpperCase();
	// }

	const [companyData, setCompanyData] = useState({});

	useEffect(() => {
		const getCompany = async function () {
			const resp = await getApi('/users/company');
			if (resp.type === 'success') {
				setCompanyData(resp.company);
			}
		};
		getCompany();
	}, []);

	useEffect(() => {
		if (view === 'employee-setup') {
			setKey('5');
		} else if (view === 'admin-setup') {
			setKey('4');
		} else if (view === 'master-setup' || view === 'purchase-order' || view === 'company-branch') {
			setKey('3');
		} else if (view === 'company-info') {
			setKey('2');
		} else if (view === 'agent') {
			setKey('6');
		} else {
			setKey('1');
		}
	}, [view]);

	const items = [
		{
			key: '1',
			label: (
				<div
					className="account-setting-label"
					onClick={() => {
						navigate('/account-settings?view=personal-info');
					}}
				>
					Personal Information
				</div>
			),
			children: (
				<div>
					<PersonalInformation />
				</div>
			),
		},
		...(CheckPermissionCondition(COMPANY_INFO_VIEW)
			? [
					{
						key: '2',
						label: (
							<div
								className="account-setting-label"
								onClick={() => {
									navigate('/account-settings?view=company-info');
								}}
							>
								Company Information
							</div>
						),
						children: <CompanyInformation />,
					},
			  ]
			: []),
		{
			key: '3',
			label: (
				<div
					className="account-setting-label"
					onClick={() => {
						navigate('/account-settings?view=master-setup');
					}}
				>
					Master Setup
				</div>
			),
			children: <CompanyMasterSetup />,
		},
		...(CheckPermissionCondition(ADMIN_SETUP_VIEW)
			? [
					{
						key: '4',
						label: (
							<div
								className="account-setting-label"
								onClick={() => {
									navigate('/account-settings?view=admin-setup&tab=admins');
								}}
							>
								Admin Setup
							</div>
						),
						children: <CompanyAdminSetup />,
					},
			  ]
			: []),
		...(CheckPermissionCondition(EMPLOYEE_SETUP_VIEW)
			? [
					{
						key: '5',
						label: (
							<div
								className="account-setting-label"
								onClick={() => {
									navigate('/account-settings?view=employee-setup');
								}}
							>
								Employee Setup
							</div>
						),
						children: <EmployeeContent />,
					},
			  ]
			: []),
		...(CheckPermissionCondition(AGENT_COMPUTERS_VIEW) && companyData?.isAgentEnabled
			? [
					// ...(CheckPermissionCondition(AGENT_COMPUTERS_VIEW) ?
					// [
					{
						key: '6',
						label: (
							<div
								className="account-setting-label"
								onClick={() => {
									navigate('/account-settings?view=agent');
								}}
							>
								Agent
							</div>
						),
						children: <AgentContent companyData={companyData} />,
					},
					// ] : [])
			  ]
			: []),
	];

	return (
		<div className="account-settings">
			<HeaderComponent />
			<div className="account-page-title">
				<div className="account-page-title-container">Account Settings</div>
			</div>
			<div className="account-settings-tabs">
				<Tabs activeKey={key} defaultActiveKey="1" items={items} />
			</div>
		</div>
	);
};

export default AccountSettings;

const PersonalInformation = function () {
	const [form] = Form.useForm();
	const user = useSelector((state) => state.appreducer.user);
	const [underlineButton, setUnderlineButton] = useState('');
	const [view, setView] = useState('personal-info');

	const navigate = useNavigate();

	const { register, handleSubmit, reset } = useForm();
	const dispatch = useDispatch();
	const [showPasswordOld, setshowPasswordOld] = useState(false);
	const [showPasswordNew, setshowPasswordNew] = useState(false);

	const onSubmit = async (data) => {
		console.log(data);
		const resp = await postApi({ ...data, email: user.email }, '/users/set-password');
		console.log(resp, 'resp');
		if (resp.type === 'error') {
			dispatch(
				showSnack('PASSWORD_SET_ERROR', {
					label: resp.error,
					timeout: 7000,
				})
			);
		} else {
			reset({ currentPassword: '', newPassword: '' });
			form.resetFields();
			dispatch(
				showSnack('PASSWORD_SET_SUCCESS', {
					label: 'Successfully changed your password.',
					timeout: 7000,
				})
			);
		}
	};
	let firstLetter = 'A';

	if (user.firstName && user.firstName.length > 0) {
		firstLetter = user.firstName[0].toUpperCase();
	}

	return (
		<div className="account-settings">
			<div className="accounts-icon">
				<div className="content">
					<div className="name">
						<div className="initial">{firstLetter}</div>
					</div>

					<div className="description">
						<div className="full-name">
							Welcome, {user.firstName} {user.lastName}
						</div>
						<div className="desc">Edit your profile, change password here</div>
					</div>

					<div className="user-info">
						<div className="email">
							<div className="heading">Email</div>
							<div className="details">{user.email}</div>
						</div>

						<div className="phone">
							<div className="heading">Work Phone</div>
							<div className="details">{user.phone ? user.phone : 'NA'}</div>
						</div>
					</div>
					<div className="handle-change-password">
						<div className="text-change-pass">Change Password</div>
						<Form
							form={form}
							layout="vertical"
							onFinish={onSubmit}
							className="change-password"
							//  onSubmit={handleSubmit(onSubmit)}
						>
							<div className="input-container">
								<div className="old-pass">
									{/* <div>Old Password</div> */}
									<Form.Item
										label="Old Password"
										name="currentPassword"
										rules={[
											{
												required: true,
												message: 'Provide current password',
											},
										]}
									>
										<div>
											<Input.Password placeholder="Enter old password" />
										</div>
									</Form.Item>
								</div>
								<div className="new-pass">
									{/* <div>New Password</div> */}
									<Form.Item
										label="New Password"
										name="newPassword"
										rules={[
											{
												required: true,
												message: 'Provide new password',
											},
										]}
									>
										<div
										// className="placeholder-new"
										>
											<Input.Password placeholder="Enter new password" />
										</div>
									</Form.Item>
								</div>
							</div>

							<div style={{ marginTop: '20px', marginBottom: '40px' }} className="drawer-mobile-btns">
								<Button
									style={{ height: '40px', width: '100px', borderRadius: '3px' }}
									size="large"
									onClick={() => {
										navigate(-1);
									}}
								>
									Cancel
								</Button>
								<Button
									type="primary"
									onClick={() => {
										form.submit();
									}}
									size="large"
									className="ant-design-button"
									style={{ height: '40px', width: '100px' }}
								>
									Save
								</Button>
							</div>

							<div className="buttons mobile-hide-btns">
								<Button
									onClick={() => {
										navigate(-1);
									}}
									// type={'button'}
									// className="cancel-button"
									className="ant-design-cancel-btn"
								>
									Cancel
								</Button>
								<Button
									type={'submit'}
									className="ant-design-save-btn"
									onClick={() => {
										form.submit();
									}}
									// className="save-button"
								>
									Save
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
};

const CompanyMasterSetup = function () {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { register, handleSubmit, reset, control } = useForm();
	const [shiftTimingsModal, setShiftTimingsModal] = useState(false);
	const addId = uuidv4();
	const [addShift, setAddShift] = useState([addId]);
	const [searchParams] = useSearchParams();
	const [key, setKey] = useState(null);
	let tab = searchParams.get('tab');
	const [form] = Form.useForm();

	useEffect(() => {
		if (tab === 'purchase-order') {
			setKey('4');
		} else if (tab === 'asset-types') {
			setKey('3');
		} else if (tab === 'asset-tag') {
			setKey('2');
		} else {
			setKey('1');
		}
	}, [tab]);

	const onSubmit = async (data) => {
		console.log(data);
		const resp = await postApi(data, '/users/update-companyMasterSetup');
		if (resp.type === 'success') {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: 'Successfully Updated Company Info',
					timeout: 3000,
				})
			);
		} else {
			dispatch(
				showSnack('COMPANY_INFO_UPDATE_FAILED', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
	};
	const addShiftHandler = (e) => {
		e.preventDefault();
		setAddShift(addShift.concat(addId));
	};
	return (
		<div className="master-setup-maindiv">
			<Tabs
				defaultActiveKey="1"
				size="large"
				activeKey={key}
				type="card"
				items={[
					...(CheckPermissionCondition(COMPANY_BRANCHES_VIEW)
						? [
								{
									label: (
										<div
											className="master-tab-options"
											onClick={() => {
												navigate('/account-settings?view=master-setup&tab=company-branch');
											}}
										>
											Company Branches
										</div>
									),
									key: '1',
									children: <CompanyBranch />,
								},
						  ]
						: []),
					...(CheckPermissionCondition(ASSET_TAG_SETUP_VIEW)
						? [
								{
									label: (
										<div
											className="master-tab-options"
											onClick={() => {
												navigate('/account-settings?view=master-setup&tab=asset-tag');
											}}
										>
											Asset Tag Setup
										</div>
									),
									key: '2',
									children: <MaserAssetTagSetup />,
								},
						  ]
						: []),
					...(CheckPermissionCondition(ASSET_TYPES_VIEW)
						? [
								{
									label: (
										<div
											className="master-tab-options"
											onClick={() => {
												navigate('/account-settings?view=master-setup&tab=asset-types');
											}}
										>
											Master Asset Types
										</div>
									),
									key: '3',
									children: <CompanyAssetTypesModal />,
								},
						  ]
						: []),
					...(CheckPermissionCondition(PURCHASE_ORDER_VIEW)
						? [
								{
									label: (
										<div
											className="master-tab-options"
											onClick={() => {
												navigate('/account-settings?view=master-setup&tab=purchase-order');
											}}
										>
											Purchase Order
										</div>
									),
									key: '4',
									children: <CreatePO />,
								},
						  ]
						: []),
				]}
			/>
		</div>
	);
};

const AddShiftComponent = () => {
	const { register } = useForm();
	const [loginTimePicker, setLoginTimePicker] = useState(false);
	const [logoutTimePicker, setLogoutTimePicker] = useState(false);
	const [time, setTime] = useState(false);
	const [timeValue, setTimeValue] = useState('');
	const options = ['9o00', '9o30', '10o00', '10o30'];
	const meridianRef = useRef(null);
	const meridianHandler = () => {
		meridianRef.current.click();
	};
	const timeHandler = (option) => {
		// setTime(true);
		setTimeValue(option);
	};
	return (
		<div className="shiftTimeContainer">
			<div className="shiftInfo">
				<div className="branch dualUserInput">
					<span>Name</span>
					<input
						type="text"
						{...register('name', {
							required: true,
						})}
					/>
				</div>
			</div>
			<div className="shiftTime">
				<div className="branch dualUserInput customTime">
					<span>Login</span>
					<div className="timeContainer" onClick={() => setLoginTimePicker(true)}>
						<BsBoxArrowInLeft className="leftIcon" />
						<input value={timeValue} />
					</div>

					{/* <TimePicker onChange={setTime} value={time} /> */}
				</div>
				<div className="branch dualUserInput customTime">
					<span>Logout</span>
					<div className="timeContainer">
						<BsBoxArrowInRight className="leftIcon" />
						<input
							type="time"
							required
							{...register('endTime', {
								required: true,
							})}
						/>
					</div>

					{/* <TimePicker onChange={setTime} value={time} /> */}
				</div>
			</div>
		</div>
	);
};

const assetOptions = [
	{ label: 'tablet', key: 'Tablet' },
	{ label: 'laptop', key: 'Laptop' },
	{ label: 'mobile', key: 'Mobile' },
];

const CompanyAssetTypesModal = () => {
	const [assetTypes, setAssetTypes] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [newAssetText, setNewAssetText] = useState('');
	const [comment, setComment] = useState('');
	const [isNonItAsset, setNonAssetType] = useState(false);
	const [deleteAssetId, setDeleteAssetId] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		getAssetTypes();
	}, []);

	async function getAssetTypes() {
		try {
			const resp = await getApi('/assetze/admin-setup/category');
			if (resp.type === 'success') {
				setAssetTypes(resp.categories);
			} else {
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			dispatch(
				showSnack('FILE_UPLOAD_ERROR', {
					label: 'Something went wrong',
					timeout: 7000,
				})
			);
		}
	}

	const deleteAssetType = async () => {
		try {
			const resp = await deleteApi('/assetze/admin-setup/category', {
				categoryId: deleteAssetId,
			});
			if (resp.type === 'success') {
				setAssetTypes(resp.companyInfo.categories);
				showSnack('FILE_UPLOAD_ERROR', {
					label: 'Successfully Deleted Asset Type',
					timeout: 7000,
				});
				setDeleteModal(false);
			} else {
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			dispatch(
				showSnack('FILE_UPLOAD_ERROR', {
					label: 'Something went wrong',
					timeout: 7000,
				})
			);
		}
	};

	const addNewAssetType = async () => {
		try {
			const assetTypeToAdd = newAssetText.toLowerCase();
			const defaultExist = assetOptions.find((el) => el.label === assetTypeToAdd);

			if (defaultExist) {
				return dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Asset Type Already Exist',
						timeout: 7000,
					})
				);
			}

			const resp = await postApi(
				{
					requestType: 'create',
					categoryName: assetTypeToAdd,
					comment,
					subCategoryList: [],
					isNonItAsset,
				},
				'/assetze/admin-setup/category'
			);
			if (resp.type === 'success') {
				setAssetTypes(resp.companyInfo.categories);
				setNewAssetText('');
				setComment('');
				setNonAssetType(false);
				getAssetTypes();
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Successfully Added Asset Type',
						timeout: 7000,
					})
				);
				setShowModal(false);
			} else {
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			dispatch(
				showSnack('FILE_UPLOAD_ERROR', {
					label: 'Something went wrong',
					timeout: 7000,
				})
			);
		}
	};

	console.log(isNonItAsset);
	const allOptions = assetOptions.concat(assetTypes);
	console.log(allOptions);

	const MasterAssetData = assetTypes.map((data) => {
		return {
			Name: data?.key,
			ITAsset: data?.isNonItAsset ? 'Non IT Asset' : 'IT Asset',
			action: (
				<MdDeleteOutline
					style={{ cursor: 'pointer', fontSize: '17px', color: 'red' }}
					onClick={() => {
						setDeleteModal(true);
						setDeleteAssetId(data?._id);
					}}
				/>
			),
			comments: data?.comment,
		};
	});
	const MasterAssetColumns = [
		{
			title: 'Name',
			dataIndex: 'Name',
			key: '1',
		},
		{
			title: 'Asset Type',
			dataIndex: 'ITAsset',
			key: '2',
		},
		{
			title: 'Comments',
			dataIndex: 'comments',
			key: '3',
		},
		...(CheckPermissionCondition(ASSET_TYPES_DELETE)
			? [
					{
						title: 'Action',
						dataIndex: 'action',
						key: '4',
						align: 'center',
					},
			  ]
			: []),
	];

	return (
		<>
			<Modal
				// contentLabel="Filter Assets"
				// onCancel={() => {
				// 	setShowModal(false);
				// }}
				// open={showModal}
				footer={null}
				// ariaHideApp={false}
				// style={customStyles}
			>
				<div className={'master-asset-type-container'}>
					<h3>Master Asset Types</h3>
					<div className={'add-container'}>
						<input
							onChange={(e) => {
								setNewAssetText(e.target.value);
							}}
							value={newAssetText}
							placeholder="New Asset Type"
							type={'text'}
						/>
						<div onClick={() => setNonAssetType(!isNonItAsset)} className="non-it-asset-container">
							{!isNonItAsset ? (
								<MdOutlineCheckBoxOutlineBlank font size={14} />
							) : (
								<MdOutlineCheckBox size={14} />
							)}
							Non IT Asset
						</div>

						<button onClick={addNewAssetType}>Add</button>
					</div>
					<div className={'category-show-container'}>
						{allOptions.map((el, index) => {
							return (
								<div className={'each-asset-type'} key={index}>
									<span>{el.key}</span>
									{el.label ? (
										<span style={{ paddingRight: '10px' }}></span>
									) : (
										<IoMdClose onClick={() => deleteAssetType(el._id)} />
									)}
								</div>
							);
						})}
					</div>
				</div>
			</Modal>
			<Modal
				title={'Delete Asset'}
				open={deleteModal}
				onOk={deleteAssetType}
				onCancel={() => setDeleteModal(false)}
				okText="Delete"
				cancelText="Cancel"
				okButtonProps={{ danger: true }}
			>
				<div>Are you sure you want to delete this Asset</div>
			</Modal>
			<div className="master-asset-div">
				<div className="size-div">
					<div className="master-asset-types-div">
						<div className="heading">Master Asset Types</div>
						<CheckPermission requiredPermission={ASSET_TYPES_CREATE}>
							<Button
								className="ant-design-button-small"
								onClick={() => {
									setShowModal(true);
								}}
							>
								Add
							</Button>
						</CheckPermission>
					</div>
					<div className="master-asset-table">
						<Table dataSource={MasterAssetData} columns={MasterAssetColumns} scroll={{ x: 700 }} />
					</div>
				</div>
			</div>

			<Drawer
				title="Add Assets"
				width={400}
				onClose={() => {
					setShowModal(false);
				}}
				open={showModal}
				extra={
					<Space className="mobile-hide-btns">
						<Button
							onClick={() => {
								setShowModal(false);
							}}
						>
							Cancel
						</Button>
						<Button
							className="ant-design-button"
							onClick={() => {
								addNewAssetType();
								// setShowModal(false);
							}}
						>
							Add
						</Button>
					</Space>
				}
			>
				<div className="master-asset-drawer">
					<div>
						<div className="label">New Asset Type</div>
						<Input
							onChange={(e) => {
								setNewAssetText(e.target.value);
							}}
							value={newAssetText}
							placeholder="New Asset Type"
						/>
					</div>
					<div>
						<div className="label">Comments</div>
						<Input
							onChange={(e) => {
								setComment(e.target.value);
							}}
							value={comment}
							placeholder="comments"
						/>
					</div>

					<Checkbox checked={isNonItAsset ? true : false} onChange={() => setNonAssetType(!isNonItAsset)}>
						Non IT Asset
					</Checkbox>
				</div>
				<div className="drawer-mobile-btns">
					<Button
						style={{ height: '40px', width: '100px', borderRadius: '3px' }}
						size="large"
						onClick={() => {
							setShowModal(false);
						}}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						onClick={() => {
							addNewAssetType();
							setShowModal(false);
						}}
						size="large"
						className="ant-design-button"
						style={{ height: '40px', width: '100px' }}
					>
						Add
					</Button>
				</div>
			</Drawer>
		</>
	);
};

const CompanyLogo = ({ logo, companyName }) => {
	const hiddenFileInput = useRef(null);
	// const [loading, setLoading] = useState(false);
	const [companyLogo, setLogo] = useState(logo);
	const dispatch = useDispatch();
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState();

	useEffect(() => {
		setLogo(logo);
	}, [logo]);

	const uploadFile = useCallback(
		async (file) => {
			setLoading(true);
			try {
				const resp = await postApi(
					{ filename: file.name, mimetype: file.type },
					'/assetze/admin-setup/company-logo/signedurl'
				);
				if (resp.type === 'success') {
					await uploadToS3(resp.url, file.type, file);
					setLogo(resp.actualUrl);
					dispatch({ type: SET_COMPANY_LOGO, payload: resp.actualUrl });
				}
				setLoading(false);
			} catch (e) {
				console.error(e);
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			} finally {
				setLoading(false);
			}
		},
		[dispatch]
	);

	const handleButtonClick = (e) => {
		hiddenFileInput.current.click();
	};
	async function onFileChange(event) {
		console.log(event.target.files[0]);
		const file = event.target.files[0];
		// const file = event.file.originFileObj;
		console.log(file);
		if (file.size > 2e6) {
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: 'File Size should be smaller than 2MB',
					timeout: 7000,
				})
			);
		} else {
			await uploadFile(file);
		}
	}

	let firstLetter = 'A';

	if (companyName && companyName.length > 0) {
		firstLetter = companyName[0].toUpperCase();
	}
	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const handleChange = (info) => {
		console.log(info);
		// if (info.file.status === 'uploading') {
		// 	setLoading(true);
		// 	return;
		// }
		// if (info.file.status === 'done') {
		// Get this url from response in real world.
		getBase64(info.file.originFileObj, (url) => {
			setLoading(false);
			setImageUrl(url);
		});
		// }
	};
	const uploadButton = (
		<button
			style={{
				border: 0,
				background: 'none',
			}}
			type="button"
		>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</button>
	);
	console.log(imageUrl);
	return (
		<>
			<div className="upload-company-logo-button">
				{/* <button type={'button'} onClick={handleButtonClick}> */}
				{!companyLogo && false ? (
					<div className={'add-image'}>{firstLetter}</div>
				) : (
					<div className="cmp-img-div">
						{companyLogo ? (
							<Image src={companyLogo} />
						) : (
							<Avatar
								style={
									{
										// backgroundColor: '#fde3cf',
										// color: '#f56a00',
									}
								}
								size={48}
								gap={4}
							>
								{firstLetter && firstLetter.toUpperCase()}
							</Avatar>
						)}

						<Button
							onClick={handleButtonClick}
							icon={<MdModeEdit />}
							type="primary"
							className="company-img-edit"
						></Button>
					</div>
				)}

				<input
					disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)}
					accept="image/png, image/gif, image/jpeg"
					style={{ display: 'none' }}
					ref={hiddenFileInput}
					type="file"
					onChange={onFileChange}
				/>
				{/* </button> */}
			</div>
		</>
	);
};

const CompanyInformation = function () {
	const { register, handleSubmit, reset, control } = useForm();
	const [logo, setLogo] = useState(null);
	const [companyName, setCompanyName] = useState('A');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const countries = useMemo(() => countryList().getData(), []);
	useEffect(() => {
		console.log('fetch saved company info');
		console.log('logo', logo);

		const getCompany = async function () {
			const resp = await getApi('/users/company');
			if (resp.type === 'success') {
				console.log(resp.company);
				const {
					country,
					workPhone,
					companyDomain,
					companyName,
					headOfficeLocation,
					officeBranch,
					logo = null,
					currencyCode,
					assetTag,
				} = resp.company;
				setLogo(logo);
				console.log(logo);
				setCompanyName(resp.company.name);
				reset({
					country,
					workPhone,
					domain: companyDomain,
					companyName,
					headOfficeLocation,
					officeBranch,
				});
				form.setFieldsValue({
					country,
					workPhone,
					domain: companyDomain,
					companyName,
					headOfficeLocation,
					officeBranch,
					currencyCode,
					assetTag,
				});
			}
		};
		getCompany();
	}, [reset]);
	const onSubmit = async (data) => {
		console.log(data);
		const resp = await postApi(data, '/users/update-companyInfo');
		console.log(resp);
		if (resp.type === 'success') {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: 'Successfully Updated Company Info',
					timeout: 3000,
				})
			);
		} else {
			dispatch(
				showSnack('COMPANY_INFO_UPDATE_FAILED', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
	};
	const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
	console.log(countries, 'countries');

	return (
		<Form
			form={form}
			className="master-setup"
			onFinish={onSubmit}
			// onSubmit={handleSubmit(onSubmit)}
		>
			<p>Edit your company information, set asset prefix, etc.,</p>
			<CompanyLogo companyName={companyName} logo={logo} />
			<div className="tag-and-currency">
				<div className="asset-tag">
					<div className="master-heading">Country</div>
					<Form.Item name="country">
						<Select
							showSearch
							disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)}
							filterOption={filterOption}
							placeholder="Country"
							options={countries}
							// style={{ width: '200px' }}
						/>
					</Form.Item>
				</div>
				<div className="currency">
					<div className="master-heading">Work Phone</div>
					{/* <input {...register('workPhone')} type="text" placeholder="8147381915" /> */}
					<Form.Item name="workPhone">
						<Input disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)} />
					</Form.Item>
				</div>
			</div>
			<div className="deprecValue-and-value">
				<div className="asset-tag">
					<div className="master-heading">Domain</div>
					{/* <input {...register('domain')} type="text" placeholder="www.ecorework.com" /> */}
					<Form.Item name="domain">
						<Input disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)} />
					</Form.Item>
				</div>
				<div className="currency">
					<div className="master-heading">Company Name</div>
					<div className="duration-months">
						{/* <input {...register('companyName')} type="text" placeholder="Assetze" /> */}
						<Form.Item name="companyName">
							<Input disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)} />
						</Form.Item>
					</div>
				</div>
			</div>
			<div className="office-details">
				<div className="asset-tag">
					<div className="master-heading">Office Headquarter</div>
					{/* <input {...register('headOfficeLocation')} type="text" placeholder="India" /> */}
					<Form.Item name="headOfficeLocation">
						<Input disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)} />
					</Form.Item>
				</div>
				<div className="currency">
					<div className="master-heading">Branch</div>
					{/* <input {...register('officeBranch')} type="text" placeholder="Jayanagar Branch" /> */}
					<Form.Item name="officeBranch">
						<Input disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)} />
					</Form.Item>
				</div>
			</div>
			<div className="tag-and-currency">
				<div className="asset-tag">
					<div className="master-heading">Asset tag prefix</div>
					{/* <input {...register('assetTag')} type="text" placeholder="Eg: DFM, TAT, MPL" /> */}
					<Form.Item name="assetTag">
						<Input disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)} />
					</Form.Item>
				</div>
				<div className="currency">
					<div className="master-heading">Currency</div>
					<Form.Item name="currencyCode">
						<Select
							disabled={!CheckPermissionCondition(COMPANY_INFO_EDIT)}
							showSearch
							placeholder="Currency"
							options={currencyList}
							// style={{ width: '200px' }}
							filterOption={filterOption}
						/>
					</Form.Item>
				</div>
			</div>

			{/* Flex grow */}
			<CheckPermission requiredPermission={COMPANY_INFO_EDIT}>
				<div style={{ marginTop: '20px', marginBottom: '40px' }} className="drawer-mobile-btns">
					<Button
						style={{ height: '40px', width: '100px', borderRadius: '3px' }}
						size="large"
						onClick={() => {
							navigate(-1);
						}}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						onClick={() => {
							form.submit();
						}}
						size="large"
						className="ant-design-button"
						style={{ height: '40px', width: '100px' }}
					>
						Save
					</Button>
				</div>

				<div className="companyInfo-action-button mobile-hide-btns">
					<Button
						onClick={() => {
							navigate(-1);
						}}
						// type={'button'}
						// className="cancel-button"
						className="ant-design-cancel-btn"
					>
						Cancel
					</Button>
					<Button
						type={'submit'}
						// className="save-button"
						onClick={() => form.submit()}
						className="ant-design-save-btn"
					>
						Save
					</Button>
				</div>
			</CheckPermission>
		</Form>
	);
};

const CompanyAdminSetup = function () {
	const user = useSelector((state) => state.appreducer.user);
	const permissions = useSelector((state) => state.appreducer.permissions);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [view, setView] = useState('admin-setup');
	const [admins, setAdmins] = useState([]);
	const [adminDrawer, setadminDrawer] = useState(false);
	const { Option } = Select;
	const [searchParams] = useSearchParams();
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [seletedRole, setSeletedRole] = useState(false);
	const [roleDropdownOptions, setRoleDropdownOptions] = useState([]);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [searchEmpLoading, setSearchEmpLoading] = useState(false);
	const [roleDetails, setroleDetails] = useState({});
	const [viewDrawer, setViewDrawer] = useState(false);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	});
	const [tableDetails, setTableDetails] = useState({
		createdAt: 0,
		updatedAt: 0,
	});
	const [key, setKey] = useState(null);
	let tab = searchParams.get('tab');

	useEffect(() => {
		if (tab === 'admins') {
			setKey('1');
		} else {
			setKey('2');
		}
	}, [tab]);

	useEffect(() => {
		getAdminsList();
		getRoleOptions();
	}, [tab]);

	useEffect(() => {
		const getrolesDetails = async () => {
			const resp = await postApi(
				{
					accessLevel: viewDrawer,
				},
				'/assetze/roles/get'
			);
			if (resp.type === 'success') {
				console.log(resp);
				setroleDetails(resp.roleInfo);
				setTableDetails({
					createdAt: resp.roleInfo?.createdAt,
					updatedAt: resp.roleInfo?.updatedAt,
				});
			}
		};
		if (viewDrawer) {
			getrolesDetails();
		}
	}, [viewDrawer]);

	const getAdminsList = async function (page, limit) {
		const resp = await postApi({ page, limit }, '/users/get-admins');
		console.log(resp);
		if (resp && resp.type === 'success') {
			setAdmins(resp.admins);
			setPagination({
				total: resp.total,
				current: page,
				pageSize: limit,
			});
		}
	};

	const getRoleOptions = async function () {
		const resp = await getApi('/assetze/roles/dropdown-list-roles');
		console.log(resp);
		if (resp && resp.type === 'success') {
			// setRoleDropdownOptions(resp.rolesList);
			setRoleDropdownOptions(
				resp.rolesList.map((el) => {
					return {
						label: el.roleName,
						value: el.roleName,
					};
				})
			);
		}
	};

	console.log(roleDropdownOptions);
	console.log(seletedRole);

	let firstLetter = getFirstLetter(user.firstName);

	const handleTableChange = (pagination) => {
		getAdminsList(pagination.current, pagination.pageSize);
	};

	const adminSetupColumn = [
		{
			title: 'User',
			dataIndex: 'user',
			key: 'user',
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
		},
		{
			title: 'Designation',
			dataIndex: 'designation',
			key: 'designation',
		},
		{
			title: 'Created On',
			dataIndex: 'createdOn',
			key: 'createdOn',
		},
		{
			title: 'Actions',
			dataIndex: 'Actions',
			key: 'Actions',
		},
	];

	const rolesColumns = [
		{
			title: 'Roles',
			dataIndex: 'Roles',
			key: 'Roles',
		},
		{
			title: 'Create',
			dataIndex: 'Create',
			key: 'Create',
		},
		{
			title: 'View',
			dataIndex: 'View',
			key: 'View',
		},
		{
			title: 'Edit',
			dataIndex: 'Edit',
			key: 'Edit',
		},
		{
			title: 'Delete',
			dataIndex: 'Delete',
			key: 'Delete',
		},
	];

	const adminSetupData = admins?.map((eachAdmin) => ({
		key: `${eachAdmin._id}`,
		user: (
			<div className="user">
				{/* <div className="user-icon">{getFirstLetter(eachAdmin.name)}</div> */}
				<Avatar
					style={{
						backgroundColor: '#33D7DB',
						verticalAlign: 'middle',
					}}
					size="medium"
					icon={getFirstLetter(eachAdmin.firstName)}
				></Avatar>
				<div className="user-details">
					<div className="user-name">{eachAdmin.firstName}</div>
					<div className="user-email">{eachAdmin.email}</div>
				</div>
			</div>
		),
		role: <div className="adminsetup-role">{eachAdmin.department}</div>,
		designation: (
			<div className="adminsetup-designation">
				{eachAdmin.accessLevel}
				<MdOutlineRemoveRedEye
					style={{ fontSize: '14px', cursor: 'pointer' }}
					onClick={() => {
						setViewDrawer(eachAdmin.accessLevel);
					}}
				/>
			</div>
		),
		createdOn: <div className="adminsetup-createdon">{format(new Date(eachAdmin.createdAt), 'dd MMM yyyy')}</div>,
		Actions: (
			<MdOutlineModeEdit
				fontSize={20}
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setSeletedRole(eachAdmin);
					form.setFieldsValue({
						assignToUserId: eachAdmin._id,
						roleName: eachAdmin.accessLevel,
					});
				}}
			/>
		),
	}));

	const updateRole = async (data) => {
		console.log(data);
		const resp = await postApi(data, '/assetze/roles/assign-to-user');
		if (resp && resp.type === 'success') {
			dispatch(
				showSnack('ERROR_MESSAGE', {
					label: 'Successfully Updated Role',
					timeout: 7000,
				})
			);
			setSeletedRole(false);
			getAdminsList();
		} else {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: resp.error,
					timeout: 3000,
				})
			);
		}
	};

	const searchEmployeeFilter = async (limit, page, value) => {
		try {
			const filterText = escapeRegExp(value);
			if (filterText && filterText.length >= 3) {
				const resp = await postApi({ limit, page, filterText }, '/users/get-admins');
				console.log(resp);
				if (resp.type === 'success') {
					setAdmins(resp.admins);
					setPagination({
						total: resp.total,
						current: resp.page,
						pageSize: resp.limit,
					});
				}
			} else {
				if (filterText.length === 0) {
					getAdminsList();
				}

				// setEmpList([]);
			}
		} catch (e) {
			console.error(e);
		}
		setSearchEmpLoading(false);
	};

	const viewTableData = roleDetails?.policies
		?.map((item) => {
			return item.permList.map((el) => {
				const matchedItem = permissions.find((permItem) => permItem.obj === item.obj);
				const matchedPerm = matchedItem ? matchedItem.permList.find((perm) => perm.obj === el.obj) : null;
				console.log(matchedItem, matchedPerm, item.obj, el.obj);

				const label = getLabel(matchedItem, matchedPerm);
				return {
					key: el.obj + item.obj,
					Roles: label,
					Create: <Checkbox checked={el.actions.includes('create')} disabled={true}></Checkbox>,
					View: <Checkbox checked={el.actions.includes('view')} disabled={true}></Checkbox>,
					Delete: <Checkbox checked={el.actions.includes('delete')} disabled={true}></Checkbox>,
					Edit: <Checkbox checked={el.actions.includes('edit')} disabled={true}></Checkbox>,
				};
			});
		})
		.flat();

	const adminItems = [
		{
			label: (
				<div
					className="master-tab-options"
					onClick={() => {
						navigate('/account-settings?view=admin-setup&tab=admins');
					}}
				>
					Admins
				</div>
			),
			key: '1',
			children: (
				<>
					<div className="admin-setup-container">
						<div className="admin-setup-innerdiv">
							<div className="admin-setup-header">
								<div>
									<p>Add or remove admins in your organization</p>
									<div>
										<Search
											placeholder="Search Admin"
											onChange={_.debounce(function (e) {
												setSearchEmpLoading(true);
												searchEmployeeFilter(
													pagination.pageSize,
													pagination.current,
													e.target.value
												);
											}, 1000)}
											type={'text'}
											loading={searchEmpLoading}
											enterButton
										/>
									</div>
								</div>
								<div>
									<CheckPermission requiredPermission={ADMIN_SETUP_CREATE}>
										<Button
											size="large"
											onClick={() => {
												setadminDrawer(true);
											}}
											// onClick={() => setView('add-user')}
											className="ant-design-button"
										>
											Add User
										</Button>
									</CheckPermission>
								</div>
							</div>
							<Table
								pagination={pagination}
								columns={adminSetupColumn}
								dataSource={adminSetupData}
								onChange={handleTableChange}
								style={{ margin: 'auto', marginTop: '1rem' }}
								scroll={{ x: 650 }}
							/>
						</div>
					</div>
					<Drawer
						title={`Role Name:- ${viewDrawer}`}
						width={600}
						onClose={() => {
							setViewDrawer(false);
						}}
						open={viewDrawer}
					>
						<div className="roles-tables">
							<Table pagination={false} dataSource={viewTableData} columns={rolesColumns} />
							<div className="table-details">
								<div>
									<b>Created Time:-</b>{' '}
									{tableDetails.createdAt &&
										format(new Date(tableDetails?.createdAt), 'yyyy-MM-dd HH:mm:ss')}
								</div>
								<div>
									<b>Last Modified Time:-</b>
									{tableDetails.updatedAt &&
										format(new Date(tableDetails?.updatedAt), 'yyyy-MM-dd HH:mm:ss')}
								</div>
							</div>
						</div>
					</Drawer>
					<Drawer
						destroyOnClose={true}
						title="Update Role"
						width={420}
						onClose={() => {
							setSeletedRole(false);
						}}
						open={seletedRole}
						styles={{
							body: {
								paddingBottom: 80,
							},
						}}
					>
						<Form form={form} onFinish={updateRole} layout="vertical" hideRequiredMark>
							<Col span={20} style={{ margin: 'auto' }}>
								<Form.Item name="assignToUserId" label="Admin Id">
									<Input
										// defaultValue={seletedRole._id}
										disabled={true}
									/>
								</Form.Item>
								<Form.Item
									name="roleName"
									label="Role Name"
									// rules={[
									// 	{
									// 		required: true,
									// 		message: 'Select Role',
									// 	},
									// ]}
								>
									<Select
										// defaultValue={seletedRole.accessLevel}
										// mode="multiple"
										placeholder="Select Role"
										options={roleDropdownOptions}
										width="100%"
									/>
								</Form.Item>
							</Col>
							<div className="mobile-hide-btns">
								<Col span={20} style={{ margin: 'auto', display: 'flex', justifyContent: 'right' }}>
									<Space>
										<Button
											onClick={() => {
												setSeletedRole(false);
											}}
										>
											Cancel
										</Button>
										<Button htmlType="submit" className="ant-design-button">
											Submit
										</Button>
									</Space>
								</Col>
							</div>
							<div className="drawer-mobile-btns">
								<Button
									style={{ height: '40px', width: '100px', borderRadius: '3px' }}
									size="large"
									onClick={() => {
										setSeletedRole(false);
									}}
								>
									Cancel
								</Button>
								<Button
									type="primary"
									onClick={() => {
										form.submit();
									}}
									size="large"
									className="ant-design-button"
									style={{ height: '40px', width: '100px' }}
								>
									Submit
								</Button>
							</div>
						</Form>
					</Drawer>
					<ModalToAddNewUser
						roleDropdownOptions={roleDropdownOptions}
						getAdminsList={getAdminsList}
						//  setView={setView} view={view}
						setadminDrawer={setadminDrawer}
						adminDrawer={adminDrawer}
					/>
				</>
			),
		},
		{
			label: (
				<div
					className="master-tab-options"
					onClick={() => {
						navigate('/account-settings?view=admin-setup&tab=roles');
					}}
				>
					Roles
				</div>
			),
			key: '2',
			children: <Roles />,
		},
	];

	return (
		<div className="admin-setup-maindiv">
			<Tabs defaultActiveKey="1" size="large" type="card" activeKey={key} items={adminItems} />
			{/* {view === 'admin-setup' ? (
				<div className="admin-setup-container">
					<div className="admin-setup-innerdiv">
						<div className="admin-setup-header">
							<p>Add or remove admins in your organization</p>
							<div>
								<CheckPermission requiredPermission={ADMIN_SETUP_CREATE}>
									<Button
										size="large"
										onClick={() => {
											setadminDrawer(true);
										}}
										// onClick={() => setView('add-user')}
										className="ant-design-button"
									>
										Add User
									</Button>
								</CheckPermission>
							</div>
						</div>
						<Table
							pagination={pagination}
							columns={adminSetupColumn}
							dataSource={adminSetupData}
							onChange={handleTableChange}
							style={{ margin: 'auto', marginTop: '1rem' }}
							scroll={{ x: 650 }}
						/>
					</div>
					<ModalToAddNewUser
						//  setView={setView} view={view}
						setadminDrawer={setadminDrawer}
						adminDrawer={adminDrawer}
					/>
				</div>
			) : null} */}
		</div>
	);
};
const options = [
	{ value: 'it-manager', label: 'IT Admin' },
	{ value: 'hr', label: 'HR' },
	{ value: 'admin', label: 'Admin' },
];

const getAdminLabel = function (level) {
	for (let x = 0; x < options.length; x++) {
		if (options[x].value === level) {
			return options[x].label;
		}
	}
};

const UpdateEmployeeName = ({ employee, hideEmployeeModal, refreshEmployees }) => {
	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
		reset,
	} = useForm();
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	useEffect(() => {
		if (employee) {
			form.setFieldsValue({
				name: employee.name,
				email: employee.email,
				// employeeId: employee.empId,
				department: employee.department,
			});
		}
	}, [employee, reset]);

	const onSubmit = async (data) => {
		try {
			const resp = await postApi({ ...data, employeeId: employee._id }, '/assets/update-employee');
			if (resp.type === 'success') {
				dispatch(
					showSnack('ERROR_MESSAGE', {
						label: 'Successfully Updated Employee',
						timeout: 7000,
					})
				);
				hideEmployeeModal(false);
				refreshEmployees();
			} else {
				dispatch(
					showSnack('ERROR_MESSAGE', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};
	return (
		<>
			<Form form={form} onFinish={onSubmit} layout="vertical" hideRequiredMark>
				<Col span={17} style={{ margin: 'auto' }}>
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{
								required: true,
								message: 'Please enter name',
							},
						]}
					>
						<Input type={'text'} placeholder={'Please enter name'} />
					</Form.Item>

					<Form.Item
						name="email"
						label="Email"
						rules={[
							{
								required: true,
								message: 'Please enter Email',
							},
						]}
					>
						<Input type={'text'} placeholder={'Please enter Email'} />
					</Form.Item>

					<Form.Item
						name="department"
						label="Department"
						rules={[
							{
								required: true,
								message: 'Please enter Department',
							},
						]}
					>
						<Input type={'text'} placeholder={'Please enter Department'} />
					</Form.Item>
					{/* <Form.Item
						name="employeeId"
						label="EmployeeID"
						rules={[
							{
								required: true,
								message: 'Please enter employeeId',
							},
						]}
					>
						<Input disabled type={'text'} placeholder={'Please enter employeeId'} />
					</Form.Item> */}
				</Col>
				<div className="mobile-hide-btns">
					<Col span={17} style={{ margin: 'auto', display: 'flex', justifyContent: 'right' }}>
						<Space>
							<Button
								onClick={() => {
									hideEmployeeModal(false);
								}}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								htmlType="submit"
								style={{ backgroundColor: '#33D7DB', border: 'none', color: 'black' }}
							>
								Update
							</Button>
						</Space>
					</Col>
				</div>
				<div className="drawer-mobile-btns">
					<Button
						style={{ height: '40px', width: '100px', borderRadius: '3px' }}
						size="large"
						onClick={() => {
							hideEmployeeModal(false);
						}}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						onClick={() => {
							form.submit();
						}}
						size="large"
						className="ant-design-button"
						style={{ height: '40px', width: '100px' }}
					>
						Update
					</Button>
				</div>
			</Form>
		</>
	);
};

const EmployeeContent = () => {
	const [employeeList, setEmpList] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [deleteEmployeeModalState, setDeleteEmployee] = useState(null);
	const email = useSelector((state) => state.appreducer.user.email);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { Search } = Input;
	const [searchEmpLoading, setSearchEmpLoading] = useState(false);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	});

	useEffect(() => {
		async function getEmplyeeList() {
			try {
				const resp = await postApi({ email }, '/users/employee-list');
				console.log(resp);
				if (resp.type === 'success') {
					setEmpList(resp.employeeList);
					setPagination({
						total: resp.total,
					});
				} else {
					throw new Error(resp.error);
				}
			} catch (e) {
				dispatch(
					showSnack('ERROR_MESSAGE', {
						label: e.message,
						timeout: 7000,
					})
				);
			}
		}
		return getEmplyeeList();
	}, []);

	const columns = [
		{
			title: 'User',
			dataIndex: 'user',
			key: 'user',
		},
		{
			title: 'Employee No.',
			dataIndex: 'employeeId',
			key: 'employeeId',
		},
		{
			title: 'Created On',
			dataIndex: 'createdOn',
			key: 'createdOn',
		},
		{
			title: 'Date Of Joining',
			dataIndex: 'doj',
			key: 'doj',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
		},
	];

	const tableData = employeeList.map((eachEmployee) => ({
		key: `${eachEmployee._id}`,
		user: (
			<div className="user">
				<Avatar
					style={{
						backgroundColor: '#33D7DB',
						verticalAlign: 'middle',
					}}
					size="medium"
					icon={getFirstLetter(eachEmployee.name)}
				></Avatar>
				<div className="user-details">
					<div className="user-name">{eachEmployee.name}</div>
					<div className="user-email">{eachEmployee.email}</div>
				</div>
			</div>
		),
		employeeId: <div className="employee-setup-id">{eachEmployee.empId}</div>,
		createdOn: (
			<div className="employee-setup-createdon">{format(new Date(eachEmployee.createdAt), 'dd MMM yyyy')}</div>
		),
		doj: (
			<div className="employee-setup-doj">
				{eachEmployee.dateOfJoining ? format(new Date(eachEmployee.dateOfJoining), 'dd MMM yyyy') : 'NA'}
			</div>
		),
		action: (
			<div>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: 2,
						fontSize: '17px',
					}}
				>
					<CheckPermission requiredPermission={EMPLOYEE_SETUP_DELETE}>
						<Tooltip title={'Delete Employee'}>
							<span style={{ cursor: 'pointer' }}>
								<AiOutlineDelete
									onClick={() => {
										setDeleteEmployee(eachEmployee);
										setIsModalVisible(true);
									}}
									color={'red'}
								/>
							</span>
						</Tooltip>
					</CheckPermission>
					<CheckPermission requiredPermission={EMPLOYEE_SETUP_EDIT}>
						<BiPencil
							onClick={() => {
								setSelectedEmployee(eachEmployee);
							}}
							style={{ cursor: 'pointer' }}
						/>
					</CheckPermission>
				</Box>
			</div>
		),
	}));

	const refreshEmployees = async (page, limit) => {
		try {
			const resp = await postApi({ email, page, limit }, '/users/employee-list');
			if (resp.type === 'success') {
				setEmpList(resp.employeeList);
				setPagination({
					total: resp.total,
					current: page,
					pageSize: limit,
				});
			} else {
				throw new Error(resp.error);
			}
		} catch (e) {
			dispatch(
				showSnack('ERROR_MESSAGE', {
					label: e.message,
					timeout: 7000,
				})
			);
		}
	};
	const customStyles = {
		content: {
			top: '50%',
			width: 'auto',
			height: 'auto',
			padding: '0',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			backgroundColor: '#f8f8f8',
			overflow: 'hidden',
			boxShadow: '12px 12px 8px rgba(85, 85, 85, 0.12)',
			borderRadius: '20px',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	const hideEmployeeModal = () => {
		setSelectedEmployee(null);
	};
	const searchEmployeeFilter = async (limit, page, value) => {
		try {
			const filterText = escapeRegExp(value);
			if (filterText && filterText.length >= 3) {
				const resp = await postApi({ limit, page, filterText }, '/users/get-employee');
				console.log(resp);
				if (resp.type === 'success') {
					setEmpList(resp.employeeList);
					setPagination({
						total: resp.total,
						current: page,
						pageSize: limit,
					});
				}
			} else {
				if (filterText.length === 0) {
					refreshEmployees();
				}

				// setEmpList([]);
			}
		} catch (e) {
			console.error(e);
		}
		setSearchEmpLoading(false);
	};
	console.log('employeeList------', employeeList);

	const deleteEmployee = async () => {
		try {
			const resp = await postApi({ employeeId: deleteEmployeeModalState._id }, '/assetze/employee/delete');
			if (resp.type === 'success') {
				dispatch(
					showSnack('EMPLOYEE_DELETE_SUCCESS', {
						label: 'Successfully deleted employee',
						timeout: 7000,
					})
				);
				hideEmployeeModal();
				refreshEmployees();
				setIsModalVisible(false);
			} else {
				dispatch(
					showSnack('EMPLOYEE_DELETE_ERROR', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleTableChange = (pagination) => {
		refreshEmployees(pagination.current, pagination.pageSize);
	};

	return (
		<div className="employee-content-container admin-setup-container">
			<Drawer
				title="Update Employee"
				width={420}
				onClose={() => {
					hideEmployeeModal();
				}}
				open={selectedEmployee != null}
				styles={{
					body: {
						paddingBottom: 80,
					},
				}}
			>
				<UpdateEmployeeName
					refreshEmployees={refreshEmployees}
					employee={selectedEmployee}
					hideEmployeeModal={hideEmployeeModal}
				/>
			</Drawer>
			<Modal
				title={'Delete Employee'}
				open={isModalVisible}
				onOk={deleteEmployee}
				onCancel={() => setIsModalVisible(false)}
				okText="Delete"
				cancelText="Cancel"
				okButtonProps={{ danger: true }}
			>
				<div>
					Are you sure you want to delete employee <b>{deleteEmployeeModalState?.name}</b> ? <br />
					All the assigned assets will be automatically unassigned.
				</div>
			</Modal>
			<Modal
				contentLabel="Filter Assets"
				onRequestClose={() => {
					setDeleteEmployee(null);
				}}
				isOpen={deleteEmployeeModalState != null}
				ariaHideApp={false}
				style={customStyles}
			>
				<DeleteEmployee
					refreshEmployees={refreshEmployees}
					employee={deleteEmployeeModalState}
					hideEmployeeModal={() => {
						setDeleteEmployee(null);
					}}
				/>
			</Modal>
			<div className="employee-setup-container">
				<div className="employee-setup-innerdiv">
					<div className="employee-setup-header">
						<div className="employee-search-container">
							<div className="head-div">
								<p>Add or remove employee in your organization</p>
								<Search
									placeholder="Search Employee"
									onChange={_.debounce(function (e) {
										setSearchEmpLoading(true);
										searchEmployeeFilter(pagination.pageSize, pagination.current, e.target.value);
									}, 1000)}
									type={'text'}
									// style={{ marginLeft: "15px" }}
									size="large"
									loading={searchEmpLoading}
									enterButton
								/>
							</div>
							<div className="btns-div" style={{ display: 'flex', alignItems: 'center' }}>
								<EmployeeModal refreshEmployees={refreshEmployees} />
							</div>
						</div>
					</div>
					<Table
						style={{ margin: 'auto', marginTop: '40px' }}
						columns={columns}
						dataSource={tableData}
						pagination={pagination}
						onChange={handleTableChange}
						scroll={{ x: 700 }}
					/>
				</div>
			</div>
		</div>
	);
};

const VendorContent = () => {
	const [responseModal, setResponseModal] = useState(false);
	const navigate = useNavigate();
	const customStyles = {
		content: {
			top: '50%',
			// width: "50vw",
			height: '100vh',
			left: '50%',
			transform: 'translate(10%, -50%)',
			backgroundColor: '#ffffff',
			boxShadow: '12px 12px 8px rgba(85, 85, 85, 0.12)',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	return (
		<div className="vendor-content-container">
			<button onClick={() => navigate('/vendor-registration')} className="add-vendor-btn">
				Create Form
			</button>
			<table>
				<thead>
					<tr className="table-heading-container">
						<th className="table-heading">Vendor Company Name</th>
						<th className="table-heading">Vendor Type</th>
						<th className="table-heading">POC</th>
						<th className="table-heading">Contact</th>
						<th className="table-heading">Email Id</th>
						<th className="table-heading">Website</th>
						<th className="table-heading"></th>
					</tr>
				</thead>
				<tbody>
					<tr className="table-data-container">
						<td className="table-data">SP Ecorework Pvt. Ltd</td>
						<td className="table-data">IT Products</td>
						<td className="table-data">Puneeth</td>
						<td className="table-data">8147381915</td>
						<td className="table-data">Puneeth@ecorework.com</td>
						<td className="table-data">www.ecorework.com</td>
						<td className="table-data">
							<BsBoxArrowUpRight onClick={() => setResponseModal(true)} />
						</td>
					</tr>
				</tbody>
			</table>
			<Modal
				contentLabel="Respone Modal"
				onRequestClose={() => setResponseModal(false)}
				isOpen={responseModal}
				ariaHideApp={false}
				style={customStyles}
			>
				<div className="responseModalContainer">
					<div className="vendorBasicInfo">
						<div className="vendorCompanyDetails">
							<h3>SP Ecorework Private Limited</h3>
							<h5>IT Products</h5>
						</div>
						<div className="vendorContantInfo">
							<h3>
								<IoMdContact />
								Puneeth Reddy
							</h3>
							<h3>
								<BsFillTelephoneFill />
								+91 8147319153
							</h3>
						</div>
						<div className="vendorEmailInfo">
							<h3>
								<AiFillMail />
								puneeth@assetze.com
							</h3>
							<h3>
								<CgWebsite />
								www.assetze.com
							</h3>
						</div>
					</div>
					<div className="vendorCustomInfo">
						<h3>Section1</h3>

						<div className="vendorLongAns">
							<h3 className="question">Question 1 with short /long ans</h3>
							<h3 className="answer">Answer 1 with long answer and deatils of the questions</h3>
						</div>
						<div className="vendorMCQ">
							<h3 className="question">Question 1 with yes/no answers or multi-choice answers</h3>
							<div className="mcq">
								<p>Yes</p>
								<p>No</p>
							</div>
						</div>

						<div className="vendorUpload">
							<h3 className="question">Question with file upload option</h3>
							<button>View File</button>
						</div>
					</div>

					<div className="vendorCustomInfo">
						<h3>Section2</h3>

						<div className="vendorLongAns">
							<h3 className="question">Question 1 with short /long ans</h3>
							<h3 className="answer">Answer 1 with long answer and deatils of the questions</h3>
						</div>
						<div className="vendorMCQ">
							<h3 className="question">Question 1 with yes/no answers or multi-choice answers</h3>
							<div className="mcq">
								<p>Yes</p>
								<p>No</p>
							</div>
						</div>

						<div className="vendorUpload">
							<h3 className="question">Question with file upload option</h3>
							<button>View File</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const ModalToAddNewUser = ({ setadminDrawer, adminDrawer, roleDropdownOptions, getAdminsList }) => {
	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm();
	const [showNewPassword, setShowNewPassword] = useState(false);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const onSubmit = async (data) => {
		console.log(data);
		const resp = await postApi(data, '/users/add-admin');
		if (resp.type === 'success') {
			dispatch(
				showSnack('ADD_ADMIN_SUCCESS', {
					label: 'Successfully added admin',
					timeout: 7000,
				})
			);
			// setView('admin-setup');
			setadminDrawer(false);
			form.resetFields();
			getAdminsList()
		} else {
			dispatch(
				showSnack('ADD_ADMIN_ERROR', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
	};

	function onClose() {
		setadminDrawer(false);
	}

	return (
		<>
			<Drawer
				title="Add Admin"
				width={480}
				destroyOnClose={true}
				onClose={() => {
					// setView('admin-setup');
					setadminDrawer(false);
				}}
				// open={view === 'add-user'}
				open={adminDrawer}
				styles={{
					body: {
						paddingBottom: 80,
					},
				}}
			>
				<Form form={form} onFinish={onSubmit} layout="vertical" hideRequiredMark>
					<Col span={18} style={{ margin: 'auto' }}>
						<Form.Item
							name="name"
							label="Full Name"
							// {...register('name')}
							rules={[
								{
									required: true,
									message: 'Provide Name',
								},
							]}
						>
							<Input placeholder="Provide Name" />
						</Form.Item>

						<Form.Item
							name="adminEmail"
							label="Email Address"
							rules={[
								{
									required: true,
									message: 'Provide Email Address',
								},
							]}
						>
							<Input placeholder="Provide Email" />
						</Form.Item>

						<Form.Item
							name="department"
							label="Department"
							rules={[
								{
									required: true,
									message: 'Provide Department',
								},
							]}
						>
							<Input placeholder="Provide Department" />
						</Form.Item>

						<Form.Item
							name="accessLevel"
							label="Role"
							rules={[
								{
									required: true,
									message: 'Select Role',
								},
							]}
						>
							<Select
								// mode="multiple"
								placeholder="Select Role"
								options={roleDropdownOptions}
								width="100%"
							/>
						</Form.Item>

						<Form.Item
							name="password"
							label="Password"
							rules={[
								{
									required: true,
									message: 'Provide Password',
								},
							]}
						>
							<Input
								placeholder="Provide Password"
								type={showNewPassword ? 'text' : 'password'}
								addonAfter={
									showNewPassword ? (
										<AiOutlineEye
											className="eye-icon"
											onClick={() => setShowNewPassword(!showNewPassword)}
										/>
									) : (
										<AiOutlineEyeInvisible
											className="eye-icon"
											onClick={() => setShowNewPassword(!showNewPassword)}
										/>
									)
								}
							/>
						</Form.Item>
					</Col>
					<div className="mobile-hide-btns">
						<Col span={18} style={{ margin: 'auto', display: 'flex', justifyContent: 'right' }}>
							<Form.Item>
								<Space style={{ marginTop: '1rem' }}>
									<Button onClick={onClose}>Cancel</Button>
									<Button htmlType="submit" className="ant-design-button">
										Submit
									</Button>
								</Space>
							</Form.Item>
						</Col>
					</div>
					<div style={{ marginTop: '60px' }} className="drawer-mobile-btns">
						<Button
							style={{ height: '40px', width: '100px', borderRadius: '3px' }}
							size="large"
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							onClick={() => {
								form.submit();
							}}
							size="large"
							className="ant-design-button"
							style={{ height: '40px', width: '100px' }}
						>
							Submit
						</Button>
					</div>
				</Form>
			</Drawer>
		</>
	);
};
function getFirstLetter(name) {
	let firstLetter = 'A';

	if (name && name.length > 0) {
		firstLetter = name[0].toUpperCase();
	}
	return firstLetter;
}

function DeleteEmployee({ employee, hideEmployeeModal, refreshEmployees }) {
	const dispatch = useDispatch();
	if (!employee) {
		return null;
	}
	const deleteEmployee = async () => {
		try {
			const resp = await postApi({ employeeId: employee._id }, '/assetze/employee/delete');
			if (resp.type === 'success') {
				dispatch(
					showSnack('EMPLOYEE_DELETE_SUCCESS', {
						label: 'Successfully deleted employee',
						timeout: 7000,
					})
				);
				hideEmployeeModal();
				refreshEmployees();
			} else {
				dispatch(
					showSnack('EMPLOYEE_DELETE_ERROR', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};
	return (
		<div className={'delete-employee-modal'}>
			<div>
				Are you sure you want delete employee {employee.name} all the assigned assets will be automatically un
				assigned?
			</div>
			<div className={'actions'}>
				<button
					className={'cancel'}
					onClick={() => {
						hideEmployeeModal();
					}}
				>
					Cancel
				</button>
				<button onClick={deleteEmployee} className={'submit'} type={'submit'}>
					Delete
				</button>
			</div>
		</div>
	);
}
