import React, { useEffect, useState } from 'react';
import './index.css';
import { Button, Checkbox, Drawer, Input, Modal, Space, Steps, Table } from 'antd';
import { postApi } from '../../services/api';
import { showSnack } from 'react-redux-snackbar';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineModeEdit } from 'react-icons/md';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';
import { format } from 'date-fns';
import { getLabel } from './getLabel';

const rolesColumns = [
	{
		title: 'Roles',
		dataIndex: 'Roles',
		key: 'Roles',
	},
	{
		title: 'Create',
		dataIndex: 'Create',
		key: 'Create',
	},
	{
		title: 'View',
		dataIndex: 'View',
		key: 'View',
	},
	{
		title: 'Edit',
		dataIndex: 'Edit',
		key: 'Edit',
	},
	{
		title: 'Delete',
		dataIndex: 'Delete',
		key: 'Delete',
	},
];

const Roles = function ({ }) {
	const [openDrawer, setOpenDrawer] = useState(false);
	// const [form] = Form.useForm();
	const dispatch = useDispatch();
	const permissions = useSelector((state) => state.appreducer.permissions);
	const [permArray, setPermArray] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);
	const [roleName, setRoleName] = useState(null);
	const [rolesList, setRolesList] = useState(null);
	const [selectedRole, setselectedRole] = useState(false);
	const [viewDrawer, setViewDrawer] = useState(false);
	const [editDrawer, setEditDrawer] = useState(false);
	const [updateRolesArr, setUpdateRolesArr] = useState([]);
	const [deleteModal, setDeleteModal] = useState(false);

	useEffect(() => {
		getrolelist();
	}, []);

	useEffect(() => {
		refreshPermArr();
	}, [permissions]);

	const getrolelist = async () => {
		const resp = await postApi(
			{
				limit: 10,
				page: 1,
			},
			'/assetze/roles/list'
		);
		if (resp.type === 'success') {
			console.log(resp);
			setRolesList(resp.rolesList);
		}
	};

	const refreshPermArr = () => {
		setPermArray(
			permissions.map((item) => {
				return {
					...item,
					permList: item.permList.map((el) => {
						return {
							...el,
							actions: [],
						};
					}),
				};
			})
		);
	};

	const handleCheckBox = (object, itemobj, val, permName) => {
		if (val) {
			setPermArray(
				permArray.map((item) => {
					return {
						...item,
						permList: item.permList.map((el) => {
							if (el.obj === object && item.obj === itemobj) {
								el.actions.push(permName);
								return el;
							}
							return el;
						}),
					};
				})
			);
		} else {
			setPermArray(
				permArray.map((item) => {
					return {
						...item,
						permList: item.permList.map((el) => {
							if (el.obj === object && item.obj === itemobj)
								return {
									...el,
									actions: el.actions.filter((ele) => {
										return ele !== permName;
									}),
								};
							return el;
						}),
					};
				})
			);
		}
	};

	const rolesData = permissions
		.map((item) => {
			return item.permList.map((el) => {
				const matchedItem = permArray.find((permItem) => permItem.obj === item.obj);
				const matchedPerm = matchedItem ? matchedItem.permList.find((perm) => perm.obj === el.obj) : null;

				return {
					key: el.obj + item.obj,
					Roles: getLabel(matchedItem, matchedPerm),
					Create: (
						<Checkbox
							checked={matchedPerm && matchedPerm.actions.includes('create')}
							disabled={!el.actions.includes('create')}
							onChange={(e) => {
								handleCheckBox(el.obj, item.obj, e.target.checked, 'create');
							}}
						></Checkbox>
					),
					View: (
						<Checkbox
							checked={matchedPerm && matchedPerm.actions.includes('view')}
							disabled={!el.actions.includes('view')}
							onChange={(e) => {
								handleCheckBox(el.obj, item.obj, e.target.checked, 'view');
							}}
						></Checkbox>
					),
					Delete: (
						<Checkbox
							checked={matchedPerm && matchedPerm.actions.includes('delete')}
							disabled={!el.actions.includes('delete')}
							onChange={(e) => {
								handleCheckBox(el.obj, item.obj, e.target.checked, 'delete');
							}}
						></Checkbox>
					),
					Edit: (
						<Checkbox
							checked={matchedPerm && matchedPerm.actions.includes('edit')}
							disabled={!el.actions.includes('edit')}
							onChange={(e) => {
								handleCheckBox(el.obj, item.obj, e.target.checked, 'edit');
							}}
						></Checkbox>
					),
				};
			});
		})
		.flat();

	const reviewData = permArray
		.map((item) => {
			return item.permList.map((el) => {
				return {
					key: el.obj + item.obj,
					Roles: getLabel(item, el),
					Create: <Checkbox checked={el.actions.includes('create')} disabled={true}></Checkbox>,
					View: <Checkbox checked={el.actions.includes('view')} disabled={true}></Checkbox>,
					Delete: <Checkbox checked={el.actions.includes('delete')} disabled={true}></Checkbox>,
					Edit: <Checkbox checked={el.actions.includes('edit')} disabled={true}></Checkbox>,
				};
			});
		})
		.flat();

	const onContinue = debounce(async () => {
		if (currentTab !== 2) {
			setCurrentTab(currentTab + 1);
		} else {
			const resp = await postApi({ policies: permArray, roleName }, '/assetze/roles/create');
			console.log(resp);
			if (resp && resp.type === 'success') {
				dispatch(
					showSnack('COMPANY_INFO_UPDATED', {
						label: 'Successfully Created Permissions',
						timeout: 3000,
					})
				);
				setCurrentTab(0);
				refreshPermArr();
				setOpenDrawer(false);
				getrolelist();
			} else {
				dispatch(
					showSnack('COMPANY_INFO_UPDATED', {
						label: resp.error,
						timeout: 3000,
					})
				);
			}
		}
	}, 100);

	const onPrevious = debounce(() => {
		setCurrentTab(currentTab - 1);
	}, 100);

	const rolesItems = [
		{
			title: 'Basic details',
			content: (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
					<div>Role Name :-</div>
					<Input
						type={'text'}
						value={roleName}
						onChange={(e) => {
							setRoleName(e.target.value);
						}}
						placeholder={'Provide Role Name'}
					/>
				</div>
			),
		},
		{
			title: 'Permissions',
			content: (
				<div className="roles-tables">
					<Table pagination={false} dataSource={rolesData} columns={rolesColumns} />
				</div>
			),
		},
		{
			title: 'Reviews',
			content: (
				<div className="roles-tables">
					<Table pagination={false} dataSource={reviewData} columns={rolesColumns} />
				</div>
			),
		},
	];

	const items = rolesItems.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	const roledata = rolesList?.map((el) => {
		return {
			RoleName: el.roleName,
			Actions: (
				<div style={{ display: 'flex', gap: '30px' }}>
					<MdOutlineRemoveRedEye
						style={{ fontSize: '16px', cursor: 'pointer' }}
						onClick={() => {
							setselectedRole(el);
							setViewDrawer(true);
						}}
					/>
					<MdOutlineModeEdit
						style={{ fontSize: '16px', cursor: 'pointer' }}
						onClick={() => {
							setselectedRole(el);
							setUpdateRolesArr(el.policies);
							setEditDrawer(true);
						}}
					/>
					<MdDeleteOutline
						style={{ fontSize: '16px', cursor: 'pointer' }}
						onClick={() => {
							setselectedRole(el);
							setDeleteModal(true);
						}}
					/>
				</div>
			),
		};
	});
	const columns = [
		{
			title: 'RoleName',
			dataIndex: 'RoleName',
			key: 'RoleName',
		},
		{
			title: 'Actions',
			dataIndex: 'Actions',
			key: 'Actions',
		},
	];

	const handleCheckBoxedit = (object, itemobj, val, permName) => {
		if (val) {
			setUpdateRolesArr(
				updateRolesArr.map((item) => {
					return {
						...item,
						permList: item.permList.map((el) => {
							if (el.obj === object && item.obj === itemobj) {
								el.actions.push(permName);
								return el;
							}
							return el;
						}),
					};
				})
			);
		} else {
			setUpdateRolesArr(
				updateRolesArr.map((item) => {
					return {
						...item,
						permList: item.permList.map((el) => {
							if (el.obj === object && item.obj === itemobj)
								return {
									...el,
									actions: el.actions.filter((ele) => {
										return ele !== permName;
									}),
								};
							return el;
						}),
					};
				})
			);
		}
	};

	const editTableData = permissions
		.map((item) => {
			return item.permList.map((el) => {
				const matchedItem = updateRolesArr?.find((permItem) => permItem.obj === item.obj);
				const matchedPerm = matchedItem ? matchedItem.permList.find((perm) => perm.obj === el.obj) : null;

				return {
					key: el.obj + item.obj,
					Roles: getLabel(matchedItem, matchedPerm),
					Create: (
						<Checkbox
							checked={matchedPerm && matchedPerm.actions.includes('create')}
							disabled={!el.actions.includes('create')}
							onChange={(e) => {
								handleCheckBoxedit(el.obj, item.obj, e.target.checked, 'create');
							}}
						></Checkbox>
					),
					View: (
						<Checkbox
							checked={matchedPerm && matchedPerm.actions.includes('view')}
							disabled={!el.actions.includes('view')}
							onChange={(e) => {
								handleCheckBoxedit(el.obj, item.obj, e.target.checked, 'view');
							}}
						></Checkbox>
					),
					Delete: (
						<Checkbox
							checked={matchedPerm && matchedPerm.actions.includes('delete')}
							disabled={!el.actions.includes('delete')}
							onChange={(e) => {
								handleCheckBoxedit(el.obj, item.obj, e.target.checked, 'delete');
							}}
						></Checkbox>
					),
					Edit: (
						<Checkbox
							checked={matchedPerm && matchedPerm.actions.includes('edit')}
							disabled={!el.actions.includes('edit')}
							onChange={(e) => {
								handleCheckBoxedit(el.obj, item.obj, e.target.checked, 'edit');
							}}
						></Checkbox>
					),
				};
			});
		})
		.flat();

	const updateRole = async () => {
		const resp = await postApi(
			{ policies: updateRolesArr, roleName: selectedRole.roleName },
			'/assetze/roles/update'
		);
		console.log(resp);
		if (resp && resp.type === 'success') {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: 'Successfully Updated Permissions',
					timeout: 3000,
				})
			);
			setEditDrawer(false);
			setCurrentTab(0);
			refreshPermArr();
		} else {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: resp.error,
					timeout: 3000,
				})
			);
		}
	};

	const deleteRole = async () => {
		const resp = await postApi({ roleName: selectedRole.roleName }, '/assetze/roles/delete');
		console.log(resp);
		if (resp && resp.type === 'success') {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: 'Successfully Deleted Role',
					timeout: 3000,
				})
			);
			setDeleteModal(false);
			getrolelist();
		} else {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: resp.error,
					timeout: 3000,
				})
			);
		}
	};

	return (
		<div>
			<Modal
				open={deleteModal}
				onCancel={() => {
					setDeleteModal(false);
				}}
				title="Delete Role"
				onOk={() => deleteRole()}
				okText="Delete"
				cancelText="Cancel"
				okButtonProps={{ danger: true }}
			>
				<div>
					<div>Are you sure you want to delete this role ?</div>
				</div>
			</Modal>
			<Drawer
				title={`Role Name:- ${selectedRole.roleName}`}
				width={600}
				onClose={() => {
					setViewDrawer(false);
				}}
				open={viewDrawer}
			>
				<ViewRole roleName={selectedRole.roleName} viewDrawer={viewDrawer} />
			</Drawer>
			<Drawer
				title={`Role Name:- ${selectedRole.roleName}`}
				width={600}
				onClose={() => {
					setEditDrawer(false);
				}}
				open={editDrawer}
				footer={
					<Space style={{ float: 'right' }}>
						<Button
							type={'primary'}
							onClick={() => {
								updateRole();
							}}
						>
							Update
						</Button>
					</Space>
				}
			>
				<div className="roles-tables">
					<Table pagination={false} dataSource={editTableData} columns={rolesColumns} />
				</div>
			</Drawer>
			<Drawer
				footer={
					<Space style={{ float: 'right' }}>
						{currentTab !== 0 ? (
							<Button
								onClick={() => {
									onPrevious();
								}}
							>
								Back
							</Button>
						) : null}

						<Button
							type={'primary'}
							onClick={() => {
								onContinue();
							}}
						>
							{currentTab === 2 ? 'Submit' : 'Continue'}
						</Button>
					</Space>
				}
				title="Create User Roles"
				width={600}
				onClose={() => {
					setOpenDrawer(false);
				}}
				open={openDrawer}
			>
				<div>
					<Steps current={currentTab} items={items} />
					<div className="roles-step-div">{rolesItems[currentTab].content}</div>
				</div>
			</Drawer>

			<div className="admin-setup-roles">
				<div>
					<Button
						className="ant-design-button"
						size={'large'}
						style={{ float: 'right', marginBottom: '20px' }}
						onClick={() => {
							setOpenDrawer(true);
						}}
					>
						Add Role
					</Button>
				</div>
				<div>
					<Table dataSource={roledata} columns={columns} />
				</div>
			</div>
		</div>
	);
};

export default Roles;

const ViewRole = function ({ roleName, viewDrawer }) {
	const [roleDetails, setroleDetails] = useState({});
	const permissions = useSelector((state) => state.appreducer.permissions);
	const [tableDetails, setTableDetails] = useState({
		createdAt: 0,
		updatedAt: 0,
	});

	useEffect(() => {
		const getrolesDetails = async () => {
			const resp = await postApi(
				{
					accessLevel: roleName,
				},
				'/assetze/roles/get'
			);
			if (resp.type === 'success') {
				console.log(resp);
				setroleDetails(resp.roleInfo);
				setTableDetails({
					createdAt: resp.roleInfo.createdAt,
					updatedAt: resp.roleInfo.updatedAt,
				});
			}
		};
		if (viewDrawer) {
			getrolesDetails();
		}
	}, [viewDrawer, roleName, viewDrawer]);

	const viewTableData = roleDetails?.policies
		?.map((item) => {
			return item.permList.map((el) => {
				const matchedItem = permissions.find((permItem) => permItem.obj === item.obj);
				const matchedPerm = matchedItem ? matchedItem.permList.find((perm) => perm.obj === el.obj) : null;

				return {
					key: el.obj + item.obj,
					Roles: getLabel(matchedItem, matchedPerm),
					Create: <Checkbox checked={el.actions.includes('create')} disabled={true}></Checkbox>,
					View: <Checkbox checked={el.actions.includes('view')} disabled={true}></Checkbox>,
					Delete: <Checkbox checked={el.actions.includes('delete')} disabled={true}></Checkbox>,
					Edit: <Checkbox checked={el.actions.includes('edit')} disabled={true}></Checkbox>,
				};
			});
		})
		.flat();

	return (
		<div className="roles-tables">
			<Table pagination={false} dataSource={viewTableData} columns={rolesColumns} />
			<div className="table-details">
				<div>
					<b>Created Time:-</b> {format(new Date(tableDetails.createdAt), 'yyyy-MM-dd HH:mm:ss')}
				</div>
				<div>
					<b>Last Modified Time:-</b>
					{format(new Date(tableDetails.updatedAt), 'yyyy-MM-dd HH:mm:ss')}
				</div>
			</div>
		</div>
	);
};
