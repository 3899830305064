import React, { useCallback, useEffect, useState } from 'react';
import { getApi, uploadToS3 } from '../../../services/api';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import { format, differenceInMonths } from 'date-fns';
import { Button, Carousel, Input, Modal, Select, Space, Spin } from 'antd';
import MobileHeader from '../MobileHeader';
import { Collapse, Divider, Steps, Card, Tag, Upload } from 'antd';
import StarRatings from 'react-star-ratings';
import { differenceInDays } from 'date-fns';
import { postApi } from '../../../services/api';
import { ApiPost } from '../../../services/agentConfig';
import prettyBytes from 'pretty-bytes';
import MapViewUI from '../../../components/Map';
import { showSnack } from 'react-redux-snackbar';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const AssetDetails = () => {
	const [assetDetails, setAssetDetails] = useState(null);
	const [deviceHistory, setDeviceHistory] = useState([]);
	const [auditHealth, setAuditHealth] = useState([]);
	const [systemInfo, setSystemInfo] = useState({});
	const [memoryInfo, setMemoryInfo] = useState([]);
	const [deprDetails, setDeprDetails] = useState({});
	const [deprData, setdeprData] = useState([]);
	const [devlocationInfo, setLocationInfo] = useState({});
	const [usersData, setUsersData] = useState([]);
	const [diskData, setDiskData] = useState([]);
	const [editAuditHealth, setEditAuditHealth] = useState(false);
	const [healthInfo, setCustomHealth] = useState([]);
	const [AssetImg, setAssetImg] = useState(0);
	const [clearImg, setClearImage] = useState(null);
	const [showModal, setModalState] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const dispatch = useDispatch();
	const { id } = useParams();
	const naviagte = useNavigate();
	const { state: routeState } = useLocation();

	useEffect(() => {
		const getAssetDetails = async function (asset_id) {
			console.log('getAssetDetailsinteranl');
			if (asset_id) {
				const resp = await postApi({ assetId: asset_id }, '/assets/get-asset');
				if (resp.type === 'success') {
					console.log(resp.data, 'resp.data');
					setAssetDetails(resp.data);
				} else {
					dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'Asset Not found',
							timeout: 7000,
						})
					);
					return naviagte(-1);
				}
			}

			// const resp = await getApi(`/assets/get-assets?filterText=${filterText}&qrcode=yes`);
			// if (resp.type === 'success') {
			// 	setAssetDetails(resp.assets[0]);
			// }
		};

		if (routeState && routeState._id) {
			console.log(routeState, 'routeState');
			getAssetDetails(routeState._id);
		}
	}, [routeState]);

	// useEffect(() => {
	// 	getAssetDetails(id);
	// }, []);

	useEffect(() => {
		setClearImage(AssetImg + 1);
	}, [AssetImg]);

	useEffect(() => {
		if (assetDetails) {
			const getHistory = async function () {
				try {
					const resp = await postApi({ assetId: assetDetails._id }, '/assetze/asset-history/history-list');
					if (resp.type === 'success') {
						setDeviceHistory(resp.assetStories);
					}
				} catch (e) {
					console.error(e);
				}
			};

			async function getAssetInfo() {
				console.log('getAssetInfo', 'assetDetails._id ', assetDetails, 'assetDetails._id', assetDetails._id);
				const resp = await postApi({ assetId: assetDetails._id }, '/assets/get-asset');
				if (resp.type === 'success') {
					console.log('asset info is found');
					const assetInfo = resp.data;
					if (assetInfo.health && assetInfo.health.length) {
						setCustomHealth(assetInfo.health);
					}
				}
			}

			const getAuditHealth = async function () {
				try {
					const resp = await ApiPost(
						{
							companyId: assetDetails.companyId,
							assetId: assetDetails._id,
							page: 1,
							limit: 10,
						},
						'/assetze/asset-audit/get'
					);
					if (resp.type === 'success') {
						setAuditHealth(resp.data);
					}
					return null;
				} catch (e) {
					console.error(e);
				}
			};

			const getFull = async function () {
				try {
					const resp = await ApiPost(
						{ serialNumber: assetDetails.serialNumber },
						'/assetze/asset-history/agent-conf-data'
					);
					if (resp.type === 'success') {
						setDiskData(resp.data.disks);
						setMemoryInfo(resp.data.memoryInfo);
						setSystemInfo(resp.data.systemInfo);

						if (resp.data.systemInfo?.deviceLocation) {
							setLocationInfo(resp.data.systemInfo?.deviceLocation);
						}
					}
					return null;
				} catch (e) {
					console.error(e);
				}
			};

			const getUsersData = async function () {
				const serialNumber = assetDetails?.serialNumber;
				try {
					const resp = await ApiPost({ serialNumber }, '/assetze/asset-history/agent-user-data');
					if (resp.type === 'success') {
						setUsersData(resp.data);
					}
					return null;
				} catch (e) {
					console.error(e);
				}
			};

			const getDepreciatonDetails = async function () {
				try {
					const resp = await ApiPost(
						{ companyId: assetDetails.companyId, assetId: assetDetails._id },
						'/assetze/depreciation/get-for-asset'
					);
					if (resp.type === 'success') {
						setDeprDetails(resp);
						setdeprData(resp.data);
					}
					return null;
				} catch (e) {
					console.error(e);
				}
			};

			getHistory();
			getAuditHealth();
			getFull();
			getUsersData();
			getDepreciatonDetails();
			getAssetInfo();
		}
	}, [assetDetails]);

	const getAssetDetails = async function (asset_id) {
		console.log('getAssetDetails123213');
		if (asset_id) {
			const resp = await postApi({ assetId: asset_id }, '/assets/get-asset');
			if (resp.type === 'success') {
				setAssetDetails(resp.data);
			}
		}

		// const resp = await getApi(`/assets/get-assets?filterText=${filterText}&qrcode=yes`);
		// if (resp.type === 'success') {
		// 	setAssetDetails(resp.assets[0]);
		// }
	};

	console.log(deviceHistory);
	console.log(devlocationInfo);

	const getHealthCondition = function (healths) {
		const conditionFound = healths?.find((el) => el.alias == 'H1' || el.alias == 'H001');
		if (conditionFound) {
			return conditionFound.value;
		}
	};

	const isWarrantyExpired = assetDetails?.warrantyExpiryDate
		? new Date(assetDetails.warrantyExpiryDate) < new Date()
		: '';

	const confs = assetDetails?.configuration?.concat(assetDetails.custom);

	const sortedArray = deprData.sort((a, b) => {
		const dateA = new Date(a.date.replace(/-/g, '/'));
		const dateB = new Date(b.date.replace(/-/g, '/'));
		return dateA - dateB;
	});
	const amount = sortedArray[0]?.amount;
	const startDate = new Date(sortedArray[0]?.date);
	const endDate =
		new Date(sortedArray[sortedArray.length - 1]?.date) < new Date()
			? new Date(sortedArray[sortedArray.length - 1]?.date)
			: new Date();
	const numberOfMonths = differenceInMonths(endDate, startDate) + 1;

	const totalHardDisk = diskData.reduce((sum, item) => sum + item.totalSpace, 0);
	const totalMemory = memoryInfo.reduce((sum, item) => sum + item.capacity, 0);

	const DEFAULT_ASSET_IMAGES = [
		'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg1.png',
		'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg2.png',
		'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg3.png',
		'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg4.png',
	];

	const Asset_Images = (images) => {
		return images && images.length > 0 ? images : DEFAULT_ASSET_IMAGES;
	};
	// const Asset_Images = assetDetails?.images || DEFAULT_ASSET_IMAGES;
	console.log(assetDetails);

	const uploadFile = useCallback(
		async (file) => {
			try {
				if (file.size > 1e6) {
					// setModalState(false);
					setSpinning(false);

					return dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'File Size should be smaller than 1MB',
							timeout: 7000,
						})
					);
				}

				const resp = await postApi(
					{
						filename: file.name,
						mimetype: file.type,
						assetId: assetDetails._id,
						clearImg: clearImg - 1,
					},
					'/assetze/asset-images/get-upload-url'
				);
				if (resp.type === 'success') {
					await uploadToS3(resp.url, file.type, file);
					const updatedResp = await postApi(
						{ originalUrl: resp.url, assetId: assetDetails._id, clearImg: clearImg - 1 },
						'/assetze/asset-images/image-upload-done'
					);
					if (updatedResp.type === 'success') {
						// updateAsset(updatedResp.assetInfo);
						dispatch(
							showSnack('INVALID_HEALTH', {
								label: 'Successfully Updated Asset Image',
								timeout: 7000,
							})
						);
						getAssetDetails(assetDetails._id);
					}
				}
				// setLoading(false);
			} catch (e) {
				dispatch(
					showSnack('FILE_UPLOAD_SUCCESS', {
						label: 'Something Went Wrong Please try after some time',
						timeout: 7000,
					})
				);
			}

			setModalState(false);
			setSpinning(false);
		},
		[assetDetails, clearImg]
	);

	const props = {
		name: 'file',
		headers: {
			authorization: 'authorization-text',
		},
		beforeUpload: (file) => {
			setSpinning(true);
			uploadFile(file);
			return false;
		},
	};

	const ShowClearImageIcon = ({ clearImg, value, onRemoveImage }) => {
		if (clearImg === value) {
			return <AiOutlineCloseCircle onClick={onRemoveImage} />;
		}
		return null;
	};

	const onRemoveImage = () => {
		console.log('onRemoveImage');
		console.log(clearImg);
		setModalState(true);
	};

	const onCarouselChange = (currentSlide) => {
		console.log(currentSlide);
		setClearImage(currentSlide + 1);
		setAssetImg(currentSlide);
	};

	return (
		<div className="qr-scanner-div">
			<div className="main-container">
				<img className="main-bg-image" alt={'bg'} src={require('../../../images/scanner/bg-2.png')} />
				<div className="main-content">
					<MobileHeader />

					<Modal
						width={430}
						onCancel={() => {
							setModalState(false);
						}}
						footer={null}
						open={showModal}
						destroyOnClose={true}
						title="Update Image"
					>
						<Spin spinning={spinning}>
							<div className="update-asset">
								{/* <h5>Update Image</h5> */}
								<div style={{ marginTop: '20px' }}>
									<img src={Asset_Images(assetDetails?.images)[AssetImg]} alt="Asset1" />
								</div>
								<br />
								<div style={{ marginTop: '20px' }}>
									<Upload accept="image/png,image/jpeg" maxCount={1} {...props}>
										<Button icon={<UploadOutlined />}>Upload Image</Button>
									</Upload>
								</div>
							</div>
						</Spin>
					</Modal>

					<div className="scanner-asset-div">
						<div className="heading">Asset Details</div>
						<div className="img-div">
							<Carousel afterChange={onCarouselChange}>
								{Asset_Images(assetDetails?.images).map((src, index) => {
									return (
										<div>
											<div>
												<AiOutlineCloseCircle
													style={{ fontSize: '20px' }}
													onClick={onRemoveImage}
												/>
											</div>
											<img
												onClick={() => {
													setAssetImg(index);
													setClearImage(index + 1);
												}}
												src={src}
												alt="assetImage"
											/>
										</div>
									);
								})}
							</Carousel>
						</div>
						{/* <div>
                        <img style={{ width: "100%" }} src={assetDetails.images[0]} />
                     </div> */}
						<div className="asset-name-div">
							<div className="asset-name">Name: {assetDetails?.name}</div>
							<div className="assetid">Asset Id: {assetDetails?.id}</div>
							<div className="assetid">Serial Number: {assetDetails?.serialNumber}</div>
						</div>

						<div className="asset-innerdiv">
							<div className="asset-username">{assetDetails?.employee && assetDetails.employee.name}</div>
							<div className="asset-userid">{assetDetails?.employee && assetDetails.employee.id}</div>
							<div className="asset-user-email">
								{assetDetails?.employee && assetDetails.employee.email}
							</div>
							{/* <div className='asset-user-no'>102400 45007</div> */}
							<div className="asset-assigned-div">
								<div className="asset-assigned-on">Assigned on:</div>
								<div className="asset-assigned-date">
									{assetDetails?.employee
										? assetDetails.employee.dateOfAssign &&
										  format(new Date(assetDetails.employee.dateOfAssign), 'dd MMM yyyy')
										: 'NA'}
								</div>
							</div>
							<div className="asset-department-div">
								<div className="asset-department">Department:</div>
								<div className="asset-department-value">
									{assetDetails?.employee && assetDetails.department ? assetDetails.department : 'NA'}
								</div>
							</div>
						</div>
						<div style={{ width: '95%', margin: 'auto', paddingBottom: '40px' }}>
							<Divider style={{ marginTop: '30px' }} orientation="left">
								Health
							</Divider>
							<Collapse
								items={[
									{
										key: '1',
										label: 'Asset Health',
										children: (
											<>
												<div className="tab-health">
													<div className="health-outerdiv">
														<div className="health-tab-div">
															<div className="health-tab-ratingdiv">
																<div className="health-tab-text">Health</div>
																<StarRatings
																	rating={assetDetails?.healthScore}
																	starEmptyColor="rgb(204,204,204)"
																	starRatedColor="rgb(253,211,5)"
																	starDimension="30px"
																	starSpacing="10px"
																/>
																<div className="health-working-condition">
																	{getHealthCondition(assetDetails?.health)} Condition
																</div>
															</div>
														</div>
														<div className="health-textdiv">
															{assetDetails?.comment ? (
																<>
																	<div className="health-comment">Comments</div>
																	<div className="health-comment-div">
																		{assetDetails?.comment}
																	</div>
																</>
															) : (
																<></>
															)}

															{/* <AddComment
                                                        updateAsset={updateAsset}
                                                        comment={assetDetails.comment}
                                                        assetId={assetDetails._id}
                                                        dispatch={dispatch}
                                                    /> */}
															<div>
																<div className="health-warranty-text">Warranty</div>
																<hr />
																<div className="health-purchase-date">
																	Purchase Date:{' '}
																	{assetDetails?.dateOfPurchase
																		? format(
																				new Date(assetDetails.dateOfPurchase),
																				'dd MMM yyyy'
																		  )
																		: 'Date of Purchase not found'}
																</div>
																<div>
																	{isWarrantyExpired ? null : (
																		<div className="expire-date">
																			<div className="date-text">
																				Expires In: &nbsp;
																			</div>

																			<div className="date-num">
																				{assetDetails?.warrantyExpiryDate
																					? `${differenceInDays(
																							new Date(
																								assetDetails?.warrantyExpiryDate
																							),
																							new Date()
																					  )} days`
																					: 'NA'}
																			</div>
																		</div>
																	)}
																</div>
																<div className="health-status">
																	Status:
																	{isWarrantyExpired ? (
																		<span>Out of Warranty</span>
																	) : (
																		assetDetails?.warrantyExpiryDate && (
																			<span>Product Still in Warranty</span>
																		)
																	)}
																</div>
																<div className="health-steps">
																	<Steps
																		// style={{ width: '500px' }}
																		progressDot
																		current={
																			new Date(
																				assetDetails?.warrantyExpiryDate
																			) <= new Date()
																				? 2
																				: 1
																		}
																		items={[
																			{
																				title: 'Purchase Date',
																				description:
																					assetDetails?.dateOfPurchase &&
																					format(
																						new Date(
																							assetDetails.dateOfPurchase
																						),
																						'dd MMM yyyy'
																					),
																			},
																			...(new Date(
																				assetDetails?.warrantyExpiryDate
																			) > new Date()
																				? [
																						{
																							title: "Today's Date",
																							description: format(
																								new Date(),
																								'dd MMM yyyy'
																							),
																						},
																				  ]
																				: []),
																			{
																				title: (
																					<div style={{ width: '160px' }}>
																						Warranty Expiry Date
																					</div>
																				),
																				description:
																					assetDetails?.warrantyExpiryDate &&
																					format(
																						new Date(
																							assetDetails.warrantyExpiryDate
																						),
																						'dd MMM yyyy'
																					),
																			},
																		]}
																	/>
																</div>
															</div>
														</div>
														<div className="health-vendor-div">
															<div className="health-vendor-text">Vendor</div>
															<div className="health-vendor-name">
																{assetDetails?.vendor ? assetDetails?.vendor : 'NA'}
															</div>
														</div>
													</div>
												</div>
											</>
										),
									},
								]}
							/>

							<Divider style={{ marginTop: '30px' }} orientation="left">
								History
							</Divider>
							<Collapse
								items={[
									{
										key: '1',
										label: 'Device History',
										children: (
											<>
												{deviceHistory.length > 0
													? deviceHistory.map((el, index) => {
															return (
																<Card
																	title={`Employee: ${el.info.name}`}
																	// bordered={false}
																	style={{
																		marginTop: '10px',
																	}}
																>
																	<p>
																		<b>Assigned On:</b>{' '}
																		{format(
																			new Date(el.info.assignedOn),
																			'dd MMM yyyy'
																		)}
																	</p>
																	<p>
																		<b>Returned On:</b>{' '}
																		{el.info.unassigned
																			? format(
																					new Date(el.info.unassigned),
																					'dd MMM yyyy'
																			  )
																			: 'NA'}
																	</p>
																	<p>
																		<b>Duration:</b>{' '}
																		{el.info.unassigned
																			? differenceInDays(
																					new Date(el.info.unassigned),
																					new Date(el.info.assignedOn)
																			  )
																			: differenceInDays(
																					new Date(),
																					new Date(el.info.assignedOn)
																			  )}
																	</p>
																	<p>
																		<b>Tickets Raised:</b> -
																	</p>
																</Card>
															);
													  })
													: 'Data Not Available'}
											</>
										),
									},
									{
										key: '2',
										label: 'Audit History',
										children: (
											<>
												{editAuditHealth ? (
													<AddHealth
														healthInfoExist={healthInfo}
														setCustomHealth={setCustomHealth}
														setEditAuditHealth={setEditAuditHealth}
														selectedAsset={assetDetails}
													/>
												) : (
													<>
														<Button
															type="primary"
															// className="edit-btn"
															style={{ color: 'black' }}
															onClick={() => {
																setEditAuditHealth(true);
															}}
														>
															<EditOutlined />
															Audit Asset
														</Button>
														{auditHealth.length > 0
															? auditHealth.map((data, index) => {
																	return (
																		<Card
																			title={`Condition: ${data.health[0]?.value}`}
																			// bordered={false}
																			style={{
																				marginTop: '10px',
																			}}
																		>
																			<p>
																				<b>Internal Parts:</b>{' '}
																				{data.health[1]?.value}
																			</p>
																			<p>
																				<b>Physical Damage:</b>{' '}
																				{data.health[2]?.value}
																			</p>
																			<p>
																				<b>Software Issue:</b>{' '}
																				{data.health[3]?.value}
																			</p>
																			<p>
																				<b>Usable Condition:</b>{' '}
																				{data.health[4]?.value}
																			</p>
																			<p>
																				<b>Ready to Dispose:</b>{' '}
																				{data.health[5]?.value}
																			</p>
																			<p>
																				<b>Date:</b>{' '}
																				{format(
																					new Date(data?.createdAt),
																					'dd MMM yyyy'
																				)}
																			</p>
																		</Card>
																	);
															  })
															: 'Data Not Available'}
													</>
												)}
											</>
										),
									},
								]}
							/>

							<Divider style={{ marginTop: '30px' }} orientation="left">
								Configuration
							</Divider>
							<Collapse
								size="small"
								items={[
									{
										key: '1',
										label: 'Custom',
										children: (
											<>
												{confs && confs.length > 0 ? (
													<Card>
														{confs &&
															confs.length > 0 &&
															confs.map((el, index) => {
																return (
																	<p>
																		<b>{el.key.replace(/-|_/, ' ')}:</b> {el.value}
																	</p>
																);
															})}
													</Card>
												) : (
													'Data Not Available'
												)}
											</>
										),
									},
									...(assetDetails?.agentLinked
										? [
												{
													key: '2',
													label: 'Device Specs',
													children: (
														<>
															<Card>
																<p>
																	<b>Manufacturer:</b>{' '}
																	{systemInfo.manufacturer && systemInfo.manufacturer}
																</p>
																<p>
																	<b>Serial Number:</b>{' '}
																	{systemInfo.serialNumber && systemInfo.serialNumber}
																</p>
																<p>
																	<b>Host Name:</b>{' '}
																	{systemInfo.hostname && systemInfo.hostname}
																</p>
																<p>
																	<b>CPU Arch:</b>
																	{systemInfo.cpuArch && systemInfo.cpuArch}
																</p>
																<p>
																	<b>Last Boot Up:</b>{' '}
																	{systemInfo.lastBootUpTime &&
																		format(
																			new Date(systemInfo.lastBootUpTime),
																			'dd-MM-yyyy hh:mma'
																		)}
																</p>
															</Card>
														</>
													),
												},
												{
													key: '3',
													label: 'Memory',
													children: (
														<>
															<div className="total-div">
																<b>Total Memory: </b>
																{totalMemory && prettyBytes(totalMemory)}
															</div>
															{memoryInfo.map((data, index) => {
																return (
																	<Card
																		title={`Media: Slot${index + 1}`}
																		style={{
																			marginTop: '10px',
																		}}
																	>
																		<p>
																			<b>Size:</b> {prettyBytes(data?.capacity)}
																		</p>
																		<p>
																			{' '}
																			<b> Manufacturer: </b>
																			{data.manufacturer}
																		</p>
																		<p>
																			{' '}
																			<b> clockSpeed: </b>
																			{data.configuredClockSpeed}
																		</p>
																		<p>
																			{' '}
																			<b> PartNo: </b>
																			{data.partNumber}
																		</p>
																		<p>
																			{' '}
																			<b> serialno: </b>
																			{data.serialNumber}
																		</p>
																	</Card>
																);
															})}
														</>
													),
												},
												{
													key: '4',
													label: 'Hard Disk',
													children: (
														<>
															<div className="total-div">
																<b>Total Hard Disk: </b>
																{totalHardDisk && prettyBytes(totalHardDisk)}
															</div>
															{diskData.map((data, index) => {
																return (
																	<Card
																		title={`Media: Slot${index + 1}`}
																		bordered={false}
																	>
																		<p>
																			<b>Size:</b> {prettyBytes(data.totalSpace)}
																		</p>
																		<p>
																			{' '}
																			<b> Manufacturer: </b>Kingston
																		</p>
																		<p>
																			{' '}
																			<b> Type: </b>
																			{data.kind}
																		</p>
																		<p>
																			{' '}
																			<b> PartNo: </b>99U5428-UF128B
																		</p>
																		<p>
																			{' '}
																			<b> FileSystem: </b>
																			{data.filesystem}
																		</p>
																	</Card>
																);
															})}
														</>
													),
												},
												{
													key: '5',
													label: 'Users',
													children: (
														<>
															{usersData.map((data, index) => {
																return (
																	<Card
																		title={`Name: ${data.name}`}
																		style={{
																			marginTop: '10px',
																		}}
																	>
																		<p>
																			<b>Domain:</b> {data.domain}
																		</p>
																		<p>
																			{' '}
																			<b>Status: </b>
																			{data.status === 'Degraded' ? (
																				<Tag color="red">{data.status}</Tag>
																			) : (
																				<Tag color="green">{data.status}</Tag>
																			)}
																		</p>
																		<p>
																			<b>Disabled:</b>{' '}
																			{data.disabled ? 'Yes' : 'No'}
																		</p>
																		<p>
																			{' '}
																			<b>Description: </b>
																			{data.description}
																		</p>
																		<p>
																			{' '}
																			<b> Password Required: </b>
																			{data.passwordRequired ? 'Yes' : 'No'}
																		</p>
																		<p>
																			{' '}
																			<b> Sid: </b>
																			{data.sid}
																		</p>
																	</Card>
																);
															})}
														</>
													),
												},

												// devlocationInfo && devlocationInfo.Latitude ?
												{
													key: '6',
													label: 'Location',
													children: (
														<>
															<div className="location-div">
																<Card
																	title={'Location'}
																	style={{
																		marginTop: '10px',
																	}}
																>
																	<MapViewUI devlocationInfo={devlocationInfo} />
																</Card>
															</div>
														</>
													),
												},
												// : null,
										  ]
										: []),
								]}
							/>

							<Divider style={{ marginTop: '30px' }} orientation="left">
								Depreciation
							</Divider>
							<Collapse
								items={[
									{
										key: '1',
										label: 'Asset Depreciation',
										children: (
											<>
												<Card title={`Overall Asset Depreciation`}>
													<p>
														<b>Date of Purchase:</b>{' '}
														{deprDetails.dateOfPurchase
															? format(new Date(deprDetails.dateOfPurchase), 'dd/MM/yyyy')
															: 'NA'}
													</p>
													<p>
														{' '}
														<b>Purchase price: </b>
														{deprDetails.price ? deprDetails.price : 'NA'}
													</p>
													<p>
														<b>Asset depreciation % in a year:</b>{' '}
														{deprDetails.depreciation
															? deprDetails.depreciation.depreciationInPer + '%'
															: 'NA'}
													</p>
													<p>
														{' '}
														<b>Salvage Percentage: </b>
														{deprDetails.depreciation
															? deprDetails.depreciation.slavagePer + '%'
															: 'NA'}
													</p>
													<p>
														{' '}
														<b> Overall depreciated amount: </b>
														{amount && numberOfMonths ? amount * numberOfMonths : 'NA'}
													</p>
												</Card>
											</>
										),
									},
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AssetDetails;

const convertOptionInputs = function (el) {
	return { value: el, label: el.charAt(0).toUpperCase() + el.slice(1) };
};

const defaultHealth = [
	{
		key: 'Condition',
		value: '',
		comment: 'fine',
		alias: 'H1',
		type: 'dropdown',
		inputs: ['working', 'repaired', 'dead'].map(convertOptionInputs),
	},
	{
		key: 'Internal Parts',
		value: '',
		comment: 'fine checked on 26th september',
		alias: 'H2',
		type: 'dropdown',
		inputs: ['present', 'damaged', 'missing'].map(convertOptionInputs),
	},
	{
		key: 'Physical Damage',
		value: '',
		comment: '',
		alias: 'H3',
		type: 'dropdown',
		inputs: ['yes', 'no'].map(convertOptionInputs),
	},
	{
		key: 'Software Issue',
		value: '',
		comment: '',
		alias: 'H4',
		type: 'dropdown',
		inputs: ['yes', 'no'].map(convertOptionInputs),
	},
	{
		key: 'Is the product ready to be disposed?',
		value: '',
		comment: '',
		alias: 'H6',
		type: 'dropdown',
		inputs: ['yes', 'no', 'already disposed'].map(convertOptionInputs),
	},
	{
		key: 'is the product still in usable condition??',
		value: '',
		comment: '',
		alias: 'H5',
		type: 'dropdown',
		inputs: ['yes', 'no'].map(convertOptionInputs),
	},
];

const AddHealth = ({ setCustomHealth, healthInfoExist, setEditAuditHealth, selectedAsset }) => {
	// const [healthInfo, setHealthInfo] = useState(healthInfoExist.length > 0 ? healthInfoExist : defaultHealth);
	const [healthInfo, setHealthInfo] = useState(defaultHealth);
	const dispatch = useDispatch();

	useEffect(() => {
		setHealthInfo(healthInfoExist);
	}, [setHealthInfo, healthInfoExist]);

	const updateHealthInfo = function (selectedHealth, type, value) {
		setHealthInfo(
			healthInfo.map((eachHealth) => {
				if (eachHealth.alias !== selectedHealth.alias) {
					return eachHealth;
				}
				if (type === 'dropdown') {
					return {
						...eachHealth,
						value,
					};
				}
				if (type === 'comment') {
					return {
						...eachHealth,
						comment: value,
					};
				}
			})
		);
	};

	const onhandleCancel = () => {
		setHealthInfo(defaultHealth);
		setEditAuditHealth(false);
	};
	console.log(selectedAsset);
	const OnhandleSave = async function () {
		try {
			const resp = await ApiPost(
				{
					companyId: selectedAsset.companyId,
					assetId: selectedAsset._id,
					health: healthInfo,
				},
				'/assetze/asset-audit/create'
			);
			if (resp.type === 'success') {
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: 'Audit History Submitted Successfully',
						timeout: 7000,
					})
				);
				setEditAuditHealth(false);
			} else {
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const props = {
		name: 'file',
		headers: {
			authorization: 'authorization-text',
		},
		beforeUpload: (file) => {
			return false;
		},
	};

	console.log(healthInfoExist);
	console.log(healthInfo);

	return (
		<div className="scanner-audit-history">
			<Card
				title="Audit History"
				extra={
					<Space>
						<Button style={{ backgroundColor: 'white' }} onClick={onhandleCancel}>
							Cancel
						</Button>
						<Button className="ant-design-button" onClick={OnhandleSave}>
							Save
						</Button>
					</Space>
				}
			>
				<div className="audit-health-div">
					<div className="audit-health-innerdiv">
						{healthInfo.map((el, index) => {
							const healthConf = defaultHealth.find((elHealth) => elHealth.alias === el.alias);

							if (!healthConf) {
								return null;
							}
							return (
								<div className="each-health-container" key={index}>
									<div className="health-name">{healthConf.key}</div>
									{healthConf.type === 'dropdown' ? (
										<Select
											value={healthConf.inputs.filter((c) =>
												el.value ? el.value.toLowerCase().includes(c.value) : null
											)}
											onChange={(val) => {
												updateHealthInfo(el, 'dropdown', val);
											}}
											className={'audit-health-select'}
											options={healthConf.inputs}
										/>
									) : null}
									<Input
										onChange={(e) => {
											updateHealthInfo(el, 'comment', e.target.value);
										}}
										className="audit-health-comments"
										placeholder={'Comments'}
									/>
								</div>
							);
						})}
					</div>
					<div>
						<Upload listType={'picture'} maxCount={2} accept="image/png,image/jpeg" {...props}>
							<Button icon={<UploadOutlined />}>Upload Image</Button>
						</Upload>
					</div>
					<p className="health-note-on-expiry">
						Note: Health score also depends on the number of years of usage and the warrarnty period
						remaining*
					</p>
				</div>
			</Card>
		</div>
	);
};
