/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import LandingPageHeader from '../LandingpageHeader';
import './index.css';
import { AiOutlineUser, AiOutlineAudit } from 'react-icons/ai';
import { IoMdLogOut } from 'react-icons/io';
// import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../redux/actions';
import { Modal, Avatar } from 'antd';
import vendor from '../../images/Vendor.png';
import { ACTIVITY_INFO_VIEW, CheckPermission } from '../../utils/Permissions';
const customStyles = {
	content: {
		top: '60px',
		left: 'auto',
		position: 'fixed',
		right: '100px',
		bottom: 'auto',
		marginRight: 'auto',
		// transform: 'translate(-50%, -50%)',
		padding: '0px',
		border: '0px',
		boxShadow: '0 0 10px #f1f1f1',
		borderRadius: '4px',
	},
	overlay: {
		backgroundColor: 'rgba(255, 255, 255, 0.4)',
	},
};

const HeaderComponent = function () {
	const [modalIsOpen, setModalState] = useState(false);
	const user = useSelector((state) => state.appreducer.user);
	const companyLogo = useSelector((state) => state.appreducer.companyLogo);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const loggedIn = useSelector((state) => state.appreducer.loggedIn);

	let firstLetter = 'A';

	if (user.firstName && user.firstName.length > 0) {
		firstLetter = user.firstName[0].toUpperCase();
	}

	const goToDashboard = () => {
		navigate('/dashboard');
	};

	if (!loggedIn) {
		return <LandingPageHeader />;
	} else {
		return (
			<div className={'header-container'}>
				<Modal
					open={modalIsOpen}
					className="header-inner-modal"
					closeIcon={false}
					footer={null}
					onCancel={() => {
						setModalState(false);
					}}
					// isOpen={modalIsOpen}
					// onRequestClose={() => {
					// 	setModalState(false);
					// }}
					// style={customStyles}
					// contentLabel="Write Post"
					// ariaHideApp={false}
				>
					<div className="accounts-icon">
						<div className="content">
							<div className="name">
								<Avatar
									style={{
										backgroundColor: '#33d7db',
										color: 'white',
									}}
									size={48}
									gap={4}
								>
									{firstLetter && firstLetter.toUpperCase()}
								</Avatar>
								{/* <div className="initial">{firstLetter}</div> */}
								<div className="name-info">
									<div className="full-name">
										{user.firstName} {user.lastName}
									</div>
									<div className="email">{user.email}</div>
								</div>
							</div>
							<div
								onClick={() => {
									navigate('/account-settings');
								}}
								className="account-settings"
							>
								<AiOutlineUser />
								<div>Account Settings</div>
							</div>
							{/* <div
								onClick={() => {
									navigate('/vendor-list');
								}}
								className="account-settings"
								style={{
									display: 'flex',
									justifyContent: 'space-around',
									alignItems: 'center',
									fontSize: '14px',

									color: '#333333',
								}}
							>
								<img src={vendor} style={{ color: '#33d7db', width: '21px' }} />
								<div>Create Vendor</div>
							</div> */}
							<CheckPermission requiredPermission={ACTIVITY_INFO_VIEW}>
								<div
									onClick={() => {
										navigate('/activity-info');
									}}
									className="account-settings"
								>
									<AiOutlineAudit />
									<div>Activity Info</div>
								</div>
							</CheckPermission>
							<div
								onClick={() => {
									localStorage.removeItem('jwt-token');
									navigate('/');
									dispatch(logoutUser());
								}}
								className="logout"
							>
								<IoMdLogOut />
								<div>Logout</div>
							</div>
						</div>
					</div>
				</Modal>
				<div
					onClick={goToDashboard}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						cursor: 'pointer',
					}}
				>
					{companyLogo ? (
						<div className="new-logo-container">
							<img className="company-logo-header" src={companyLogo} />
							<div className="assetze-logo">
								<div className="info-head">Powered By</div>
								<img src={require('../../images/logo-5.png')} className="logo-header" />
							</div>
						</div>
					) : (
						<img src={require('../../images/logo-5.png')} className="logo-header" />
					)}
				</div>
				<div className="header-info">
					<div className="notifications">
						<IoIosNotificationsOutline />
					</div>
					<div onClick={() => setModalState(true)} className="accounts-icon">
						{companyLogo && false ? (
							<img className="company-logo-header" src={companyLogo} />
						) : (
							<div>{firstLetter}</div>
						)}
					</div>
				</div>
			</div>
		);
	}
};

export default HeaderComponent;
