import React, { useState, useEffect } from 'react';
import { getApi, postApi } from '../../services/api';
import TipsCarousel from '../../components/Tips';
import AnalysisContent from '../../components/Analysis';
import DashboardFooter from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useNavigate } from 'react-router-dom';
import AssetIcon from '../../images/asset-icon.jpg';
import ComputerIcon from '../../images/computer-icon.png';
import DisposeItem from '../../images/dispose-icon.png';
import RunMaintenance from '../../images/run-maintenance.png';
import GraphIcon from '../../images/graph-temp.png';
import HealthIcon from '../../images/health-icon.png';
import { showSnack } from 'react-redux-snackbar';

import ExitIcon from '../../images/exit-icon.png';
import vendor from '../../images/Vendor.png';
import moveToVendor from '../../images/moveToVendorList.png';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { format } from 'date-fns';
import { BiLinkExternal } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'react-google-charts';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import Tooltip from '@mui/material/Tooltip';
import { Select, Space } from 'antd';
import { getLocationsDropdown } from './utils';

const percentage = 66;

const CategoryPieChart = function ({ assetTypes = [] }) {
	const data = [
		['Asset Type', 'Total'],
		...assetTypes.map((el) => {
			return [el._id, el.total];
		}),
	];
	const options = {
		// title: 'My Daily Activities',
		legend: 'none',
		pieHole: 0.8,
		is3D: false,
		chartArea: { width: '80%', height: '80%' },
	};

	return <Chart chartType="PieChart" data={data} options={options} width={'150px'} height={'150px'} />;
};

const AssetDashboard = function () {
	const navigate = useNavigate();
	const [upcomiongJoinees, setUpcomiongJoinees] = useState([]);
	const [officeLocation, setOfficeLocations] = useState([]);
	const [selectedOfficeId, setSelectedOfficeId] = useState('');
	const companyRoles = useSelector((state) => state.appreducer.companyRoles);
	const dispatch = useDispatch();
	const [assetData, setAssetData] = useState([]);
	useEffect(() => {
		const fetcUpcomingJoinees = async () => {
			const resp = await postApi({}, '/assets/upcoming-employees');
			if (resp.type === 'success') {
				console.log(resp);
				setUpcomiongJoinees(resp?.upcomingEmployees);
			}
		};

		const getLocs = async () => {
			const locs = await getLocationsDropdown();
			setOfficeLocations(locs);
		};

		getAssetData();
		fetcUpcomingJoinees();
		getLocs();
	}, []);

	useEffect(() => {
		getAssetData();
	}, [selectedOfficeId]);

	const getAssetData = async function () {
		const resp = await postApi({}, '/assets/dashboard?officeId=' + selectedOfficeId);
		if (resp.type === 'success') {
			setAssetData(resp.data);
			//console.log(incidents);
		}
	};

	const handleChange = (value) => {
		console.log(`selected ${value}`);

		if (value) {
			setSelectedOfficeId(value);
		} else {
			setSelectedOfficeId('');
		}
	};

	const nonWorking = assetData.totalAssets - assetData.workingAssets;
	const nonWorkingPercentage = Math.floor((nonWorking * 100) / assetData.totalAssets);

	const inStockPercentage = Math.floor((assetData.inStock * 100) / assetData.totalAssets);
	console.log(assetData);

	const officeLocs = officeLocation.map((el) => {
		return {
			label: el.branchId,
			value: el._id,
		};
	});

	return (
		<div className="asset-dashboard">
			<HeaderComponent />
			<div className="dashboard-body">
				<div className="dashboard-heading">
					<div className="name-1">Dashboard</div>

					<Select
						value={selectedOfficeId}
						style={{
							width: 200,
						}}
						allowClear
						options={officeLocs}
						onChange={handleChange}
						placeholder="select organisation branch"
					/>

					{/* <div className="slash">/</div>
					<div className="name-2">Asset Management</div> */}
				</div>
				<div className="body">
					<div className="dashboard-left">
						<div className="left-1">
							<div className="body-box">
								<img src={AssetIcon} />
								<div className="box-value">{assetData.totalAssets}</div>
								<div className="box-name">Total</div>
							</div>
							<div
								onClick={() => {
									navigate('/assets', {
										state: {
											filter: {
												name: 'assetCondition',
												value: { name: 'working', label: 'Working' },
											},
										},
									});
								}}
								className="body-box hover"
							>
								<div>
									<div>
										<img className="img" src={ComputerIcon} />
										<div className="box-value">{assetData.workingAssets}</div>
									</div>
									<div style={{ width: 45, height: 35 }}>
										<CircularProgressbar
											value={assetData.itAssetHealth}
											text={`${assetData.itAssetHealth}%`}
											styles={buildStyles({
												pathColor: `rgba(94, 251, 161)`,
												textColor: '#000',
											})}
										/>
									</div>
								</div>
								<div className="box-name">Working</div>
							</div>
							<div
								onClick={() => {
									navigate('/assets', {
										state: {
											filter: {
												name: 'assetCondition',
												value: { name: 'notWorking', label: 'Not Working' },
											},
										},
									});
								}}
								className="body-box hover"
							>
								<div>
									<div>
										<img src={AssetIcon} />
										<div className="box-value">{nonWorking}</div>
									</div>
									<div style={{ width: 45, height: 35 }}>
										<CircularProgressbar
											value={nonWorkingPercentage}
											text={`${nonWorkingPercentage}%`}
											styles={buildStyles({
												pathColor: `rgba(94, 251, 161)`,
												textColor: '#000',
											})}
										/>
									</div>
								</div>
								<div className="box-name">Non-working</div>
							</div>
							<div className="body-box">
								<div>
									<div>
										<img src={DisposeItem} />
										<div className="box-value">{assetData.readyToDispose}</div>
									</div>
									<div style={{ width: 45, height: 35 }}>
										<CircularProgressbar
											value={assetData.readyToDispose}
											text={`${assetData.readyToDispose}%`}
											styles={buildStyles({
												pathColor: `rgba(94, 251, 161, ${percentage / 100})`,
												textColor: '#000',
											})}
										/>
									</div>
								</div>
								<div className="box-name">E-waste Dispose</div>
							</div>
						</div>
						<div className="left-2">
							{/* <div className="run-maintenance">
								<div className="heading">Run Maintenance</div>
								<img
									style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
									src={RunMaintenance}
								/>
								<div className="note">Your monthly report is ready and ready to be downloaded</div>
								<button
									onClick={() => {
										navigate('/diagnostics');
									}}
								>
									Download Report
								</button>
							</div> */}
							<div className="body-centre">
								<div className="upcoming-joinees">
									<div className="heading">Upcoming Joinees</div>
									<table className="upcoming-joinee-container">
										<thead>
											<tr>
												<th>Name</th>
												<th>Device</th>
												<th>DOJ</th>
												<th>Comments</th>
											</tr>
										</thead>
										<tbody>
											{upcomiongJoinees?.map((newEmp) => {
												return (
													<tr key={newEmp?._id}>
														<td>{newEmp?.name}</td>
														<td>
															<Tooltip
																title={getNames(
																	newEmp.devicesProvided,
																	newEmp.unavailableDevices
																)}
															>
																<span>{newEmp?.devicesProvided[0]?.name}</span>
															</Tooltip>
														</td>
														<td>
															{format(new Date(newEmp?.dateOfJoining), 'dd MMM yyyy')}
														</td>
														<td>
															<Tooltip title={newEmp?.comments}>
																<span className="comment-design">
																	{newEmp?.comments}
																</span>
															</Tooltip>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
								<div className="stock-return">
									<div className="body-box">
										<div>
											<div>
												<img src={AssetIcon} />
												<div className="box-value">{assetData.inStock}</div>
											</div>
											<div style={{ width: 45, height: 35 }}>
												<CircularProgressbar
													value={inStockPercentage}
													text={`${inStockPercentage}%`}
													styles={buildStyles({
														pathColor: `rgba(94, 251, 161, ${percentage / 100})`,
														textColor: '#000',
													})}
												/>
											</div>
										</div>
										<div
											className="box-name"
											onClick={() => {
												navigate('/in-stock');
											}}
										>
											In-stock <BiLinkExternal />
										</div>
									</div>
									{/* <div className="body-box">
                    <div>
                      <div>
                        <img src={AssetIcon} />
                        <div className="box-value">0</div>
                      </div>
                      <div style={{ width: 45, height: 35 }}>
                        <CircularProgressbar
                          value={0}
                          text={`${0}%`}
                          styles={buildStyles({
                            pathColor: `rgba(94, 251, 161, ${percentage / 100})`,
                            textColor: "#000",
                          })}
                        />
                      </div>
                    </div>
                    <div className="box-name">Due for return</div>
                  </div> */}
									<div
										onClick={() => {
											dispatch(
												showSnack('INVALID_HEALTH', {
													label: 'Coming soon!',
													timeout: 7000,
												})
											);
										}}
										style={{ cursor: 'pointer' }}
										className="body-box"
									>
										<div>
											<div>
												<img src={vendor} />
												<div className="box-value">{assetData.vendorListCount}</div>
											</div>
										</div>
										<div className="box-name">Vendor Details</div>
									</div>
								</div>
							</div>
							<div className="non-it">
								<div className="heading">Asset Count</div>
								<div className="count">{assetData.totalAssets}</div>
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<CategoryPieChart assetTypes={assetData.assetTypes} />
									{/* <CircularProgressbar
										value={percentage}
										text={`${percentage}%`}
										styles={buildStyles({
											pathColor: `rgba(94, 251, 161, ${percentage / 100})`,
											textColor: '#000',
										})}
									/> */}
								</div>
								{/* <div className="suffix">of Total Assets</div> */}
								<table className="assettype-table-container">
									<tbody>
										{assetData.assetTypes &&
											assetData.assetTypes.map((el, index) => (
												<tr key={index}>
													<td className="asset-type-name">{el._id}</td>
													<td>{el.total}</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="dashboard-right">
						<div className="right-top">
							<div className="asset-health">
								<div className="left">
									<div>
										<img src={HealthIcon} />
										<div>Asset Health</div>
									</div>
									<div style={{ width: 81, height: 81, marginLeft: '35px' }}>
										<CircularProgressbar
											value={assetData.itAssetHealth}
											text={`${assetData.itAssetHealth}%`}
											styles={buildStyles({
												pathColor: `rgba(240, 64, 117, ${percentage / 100})`,
												textColor: '#000',
											})}
										/>
									</div>
								</div>
								<div className="right">
									{/* 
                                
                                <Chart
                                   
                                   chartType="PieChart"
                                   loader={<div>Loading Chart</div>}
                                   data={data}
                                   options={options}
                                   rootProps={{ 'data-testid': '2' }}
                                />
                                */}
								</div>
							</div>
							<p>Improve Health score by resolving the service tickets</p>
							<table>
								<tbody>
									<tr>
										<td>Recent</td>
										<td>{assetData.recent}</td>
									</tr>
									<tr>
										<td>Pending</td>
										<td>{assetData.pendingTickets}</td>
									</tr>
								</tbody>
							</table>
							<div className="health-score">
								<p>Improve health score by</p>
							</div>
							{companyRoles.includes('incident-management') ? (
								<button
									onClick={() => {
										navigate('/incidents');
									}}
								>
									View Tickets
								</button>
							) : null}
						</div>
						<div className="right-bottom">
							<div className="count-icon">
								<div className="count">0</div>
								<img src={ExitIcon} />
							</div>
							<p>Exits this week</p>
							<div>Pick up of laptops to be scheduled</div>
						</div>
					</div>
				</div>
			</div>
			<DashboardFooter />
		</div>
	);
};

export default AssetDashboard;

function getNames(devicesProvided, unavailableDevices) {
	let str = '';
	for (let x = 0; x < devicesProvided.length; x++) {
		str += devicesProvided[x].name;
		if (x === devicesProvided.length - 1) {
		} else {
			str += ', ';
		}
	}

	if (unavailableDevices?.length > 0) {
		str += ', ';
		for (let x = 0; x < unavailableDevices.length; x++) {
			str += unavailableDevices[x];
			if (x === unavailableDevices.length - 1) {
				str += '.';
			} else {
				str += ', ';
			}
		}
	} else {
		str += '.';
	}

	return str;
}
