import { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';

// Styles
import './index.css';

// Qr Scanner
import QrScanner from 'qr-scanner';
import QrFrame from '../../../images/scanner/qr-frame.svg';
import { useNavigate } from 'react-router-dom';
import MobileHeader from '../MobileHeader';

const Scanner = () => {
	const [openQr, setOpenQr] = useState(false);

	return (
		<div className="qr-scanner-div">
			<div className="main-container">
				<img className="main-bg-image" alt={'bg'} src={require('../../../images/scanner/bg-2.png')} />
				<div className="main-content qr-main-div">
					<MobileHeader />
					<div className="scanner-div">
						{openQr ? (
							<QrReader />
						) : (
							// <p>Scan QR-Code</p>
							<div>
								<img
									style={{ borderRadius: '30px' }}
									src={require('../../../images/scanner/scanner-image.jpeg')}
								/>{' '}
							</div>
						)}
					</div>
					<Button
						className="ant-design-scanner-button"
						style={{ width: '50%', margin: '0 auto', marginTop: '40px' }}
						onClick={() => setOpenQr(!openQr)}
					>
						{openQr ? 'Close' : 'Open'} QR Scanner
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Scanner;

const QrReader = () => {
	// QR States
	const scanner = useRef();
	const videoEl = useRef(null);
	const qrBoxEl = useRef(null);
	const [qrOn, setQrOn] = useState(true);
	const navigate = useNavigate();

	// Result
	const [scannedResult, setScannedResult] = useState('');

	// Success
	const onScanSuccess = (result) => {
		// 🖨 Print the "result" to browser console.
		console.log(result);
		// ✅ Handle success.
		// 😎 You can do whatever you want with the scanned result.
		setScannedResult(result?.data);
		console.log(atob(result?.data));
		const decodedData = atob(result?.data);
		var parsedData = JSON.parse(decodedData);
		console.log(parsedData.id);
		if (parsedData._id) {
			navigate(`/scanner/asset/view`, {
				state: parsedData,
			});
		}
	};

	// Fail
	const onScanFail = (err) => {
		// 🖨 Print the "err" to browser console.
		console.log(err);
	};

	useEffect(() => {
		if (videoEl?.current && !scanner.current) {
			// 👉 Instantiate the QR Scanner
			scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
				onDecodeError: onScanFail,
				// 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
				preferredCamera: 'environment',
				// 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
				highlightScanRegion: true,
				// 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
				highlightCodeOutline: true,
				// 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
				overlay: qrBoxEl?.current || undefined,
			});

			// 🚀 Start QR Scanner
			scanner?.current
				?.start()
				.then(() => setQrOn(true))
				.catch((err) => {
					if (err) setQrOn(false);
				});
		}

		// 🧹 Clean up on unmount.
		// 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
		return () => {
			if (!videoEl?.current) {
				scanner?.current?.stop();
			}
		};
	}, []);

	// ❌ If "camera" is not allowed in browser permissions, show an alert.
	useEffect(() => {
		if (!qrOn)
			alert('Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.');

		console.log(scanner.current);
	}, [qrOn]);

	return (
		<div className="qr-reader">
			{/* QR */}
			<video ref={videoEl}></video>
			<div ref={qrBoxEl} className="qr-box">
				<img src={QrFrame} alt="Qr Frame" width={256} height={256} className="qr-frame" />
			</div>

			{/* Show Data Result if scan is success */}
			{scannedResult && (
				<p
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 99999,
						color: 'white',
					}}
				>
					Scanned Result: {scannedResult}
				</p>
			)}
		</div>
	);
};
